<template>
    <div id="carousel">
        <vueper-slides slide-multiple duration="4000" v-if="visibleSlides" :visible-slides="visibleSlides"
            :autoplay="spotlight.carousel_autoplay" :arrows="spotlight.carousel_show_arrows" :arrows-outside="false"
            class="no-shadow" :slide-ratio="1 / 4" :dragging-distance="70" :bullets="spotlight.carousel_show_pages">
            <vueper-slide v-for="i in testimonials.length" :key="i">
                <template #content>
                    <TestimonialCard @setHeight="setHeight" :spotlight="spotlight" :key="testimonials[i - 1].id"
                        :autoplay="false" :testimonial="testimonials[i - 1]" />
                </template>
            </vueper-slide>
            <template #arrow-left>
                <div class="left mr-0">
                    <v-btn size="x-small" :color="spotlight.color || primary" rounded="xl" icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </div>
            </template>

            <template #arrow-right>
                <div class="left mr-0">
                    <v-btn size="x-small" :color="spotlight.color || primary" rounded="xl" icon>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </template>

            <template #bullet="{ active, slideIndex, index }">
                <v-icon :color="active ? spotlight.color || primary : 'grey'"
                    style="font-size: 15px; cursor: pointer;">mdi-circle</v-icon>
            </template>
        </vueper-slides>
        <div class="call-to-action-container w100 d-flex aic jcc mb-4">
            <v-btn rounded="xl" @click="clickCTA()"
                v-if="spotlight?.show_call_to_action && spotlight?.call_to_action_text" class="mt-2"
                :color="spotlight.color || primary">
                {{ spotlight?.call_to_action_text }}
            </v-btn>
        </div>
        <div class="ad" v-if="spotlight?.show_skooad_brand">
            <div class="clickable aic wfc" @click="$r.openInNewTab('https://skooad.com'), $emit('addClickCTASkooad')">
                <div class="text" style="margin-right: 2px; margin-top: 3px">
                    Made with
                </div>
                <img src="https://app.skooad.com/skooad/public/assets/logosimple.png" style="height: 17px;"
                    class="mb-0 mt-0" />
            </div>
        </div>
    </div>
</template>

<script>
import TestimonialCard from './TestimonialCard.vue';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    props: {
        testimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TestimonialCard,
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            index: 0,
            page: 0,
            visibleSlides: 0
        };
    },
    created() {
        this.$nextTick(() => {
            this.updateVisibleSlides();
            window.addEventListener('resize', this.updateVisibleSlides);
            this.$emit('addView')
        })

        //call setHeight after dom is rendered
        window.addEventListener('load', this.setHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateVisibleSlides);
    },
    methods: {
        clickCTA() {
            if (!this.editing) {
                this.$emit('addClickCTA');
            }
            this.$r.openInNewTab(this.spotlight?.call_to_action_link);
        },
        setHeight() {
            setTimeout(() => {
                let testicards = document.getElementsByClassName('testicard');
                let maxHeight = 0;
                for (let i = 0; i < testicards.length; i++) {
                    if (testicards[i].offsetHeight > maxHeight) {
                        maxHeight = testicards[i].offsetHeight;
                    }
                }
                document.getElementsByClassName('vueperslides__parallax-wrapper')[0].style.height = maxHeight + 40 + 'px';
            }, 1000);
        },
        updateVisibleSlides() {
            this.visibleSlides = Math.floor(document.getElementById('carousel').offsetWidth / 350);
            if (this.visibleSlides < 1) this.visibleSlides = 1;
        }
    }
};
</script>

<style>
.vueperslides__track-inner {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 20px;
}

.carousel-enter-active,
.carousel-leave-active {
    transition: transform 0.5s ease;
}

.vueperslides__track {
    padding-top: 20px;
}

.carousel-enter,
.carousel-leave-to {
    transform: translateX(100%);
}

.vueperslide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vueperslides__parallax-wrapper {
    height: 625px;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
</style>

<style scoped lang="scss">
// :deep(.answers) {
//     max-height: 200px;
//     overflow-y: auto;
//     overflow-x: hidden;
// }

:deep(.testicard) {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03) !important;
    border: 1px solid #e0e0e0;
    min-width: 300px;
    max-width: 450px;
    width: unset;
    // width: -webkit-fill-available;
    margin: 0 10px;
}

.ad {
    display: flex;
    justify-content: center;
    align-items: center !important;

    font-size: 12px;
    color: #5c5c5c;
    margin: 0 0px;

    .clickable {
        .text {
            white-space: nowrap;
        }

        cursor: pointer;

        &:hover {
            transform: scale(1.05);
            transition: transform 0.5s;
        }

    }

}

:deep(.vueperslides__bullet) {
    margin: 0 5px 10px 5px;
}
</style>