<template>
    <div>
        <v-dialog v-model="$r.dialogConfirm.is" max-width="390" persistent content-class="dialog-confirm">
            <v-card class="card">
                <v-card-title>
                    <h2>{{ $r.dialogConfirm.title }}</h2>
                </v-card-title>
                <v-card-text>{{ $r.dialogConfirm.message }}</v-card-text>
                <div>
                    <div class="buttons d-flex jcsb aic mb-4">
                        <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(50% - 20px);"
                            @click="$r.dialogConfirm.is = false">Cancel</v-btn>

                        <v-btn rounded="xl" elevation="0" class="button-primary" style="width: calc(50% - 20px);"
                            @click="$r.dialogConfirm.is = false; $r.dialogConfirm.callback()">Confirm</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DialogConfirm',
    created() {
        //listen enter key
        window.addEventListener('keyup', this.keyup)
    },
    methods: {
        keyup(e) {
            if (e.key === 'Enter') {
                if (this.$r.dialogConfirm.is) {
                    this.$r.dialogConfirm.is = false
                    this.$r.dialogConfirm.callback()
                }
            }
        }
    }
}
</script>

<style lang="scss">
.dialog-confirm {
    .card {
        border-radius: 10px !important;
        padding-top: 10px !important;
    }
}
</style>