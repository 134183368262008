<template>
    <div class="h100">
        <div class="d-flex jcsb aic fw">
            <div class="filters d-flex aic jcfs w100" v-if="!$r.mobile">
                <Input class="filter mr-4" v-for="filtre in filtres" :object="filtresValue" :input="filtre"
                    :value="filtresValue[filtre.model]" />
            </div>
            <v-btn color="primary" elevation="0" rounded="xl" class="mt-4 mb-4" @click="$r.createStory.is = true">+ Create a
                story</v-btn>
        </div>

        <div class="stories w100" v-if="testimonialsFiltereds.length">
            <div v-for="testimonial in testimonialsFiltereds" :key="testimonial.id" class="mb-4 d-flex jcc"
                :class="{ 'added': testimonial.added }">
                <TestimonialCard :spotlightBtns="fromSpotlight" :autoplay="false" :testimonial="testimonial"
                    @deleteTestimonial="deleteTestimonialConfirm" :deleteBtn="true" @addTestimonial="addTestimonial"
                    @removeTestimonial="removeTestimonial" />
            </div>
        </div>

        <div v-else-if="!testimonials?.length" class="info h100 w100 d-flex aic jcc fdc"
            @click="$emit('updateView', 'magnets')">
            <div class="cp">
                No stories yet? Share a magnet to start gathering new ones.
            </div>
        </div>

    </div>
</template>

<script>
import { supabase } from '@/supabase'
import TestimonialCard from '@/components/TestimonialCard.vue';
import Input from '@/components/inputs/Input.vue';

export default {
    name: "stories",
    data() {
        return {
            testimonials: [],
            testimonialsFiltereds: [],
            filtresValue: {
                type: 'All',
                magnet: null,
                rating: null,
                search: ''
            },
            filtres: [
                {
                    update: false,
                    name: 'Type',
                    type: 'dropdown',
                    model: 'type',
                    callback: this.filterUpdate,
                    options: [
                        'All', 'Video', 'Text'
                    ]
                },
                {
                    update: false,
                    name: 'Magnet',
                    type: 'dropdown',
                    model: 'magnet',
                    callback: this.filterUpdate,
                    options: []
                },
                {
                    update: false,
                    name: 'Rating',
                    type: 'dropdown',
                    model: 'rating',
                    callback: this.filterUpdate,
                    options: [1, 2, 3, 4, 5],
                    multiple: true
                },
                // {
                //     update: false,
                //     name: 'Search',
                //     type: 'text',
                //     model: 'search',
                //     callback: this.filterUpdate,
                //     hideDetails: true
                // }

            ],
        };
    },

    props: {
        fromSpotlight: {
            type: Boolean,
            default: false
        }
    },

    components: {
        TestimonialCard,
        Input
    },

    async created() {

        //set filter with url params like ?magnet=d689c94d-d15c-4803-8796-f1cb58755c7c
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('magnet')) {
            this.filtresValue.magnet = urlParams.get('magnet');
            window.history.replaceState({}, document.title, window.location.pathname);
        }


        this.testimonials = this.$r.stories;
        let stories = await this.$r.testisofspot.filter(testi => testi.spotlight === this.$route.params.id);
        this.testimonials?.forEach(testimonial => {
            testimonial.added = stories.some(story => story.testimonial === testimonial.id);
        });

        this.testimonialsFiltereds = this.testimonials


        this.filtres.find(filtre => filtre.name === 'Magnet').options = this.$r.magnets.map(magnet => {
            return {
                value: magnet.id,
                text: magnet.name
            }
        });
        this.filterUpdate();
    },

    methods: {
        async removeTestimonial(id) {
            this.testimonials.find(testi => testi.id === id).added = false
            let response = await supabase.from('testisofspot').delete().eq('testimonial', id)
            this.$emit('loadspotlight', this.$route.params.id)
        },
        async addTestimonial(testimonial) {
            this.testimonials.find(testi => testi.id === testimonial.id).added = true
            let response = await supabase.from('testisofspot').insert({
                spotlight: this.$route.params.id,
                testimonial: testimonial.id,
                user: this.$r.user.id
            })
            this.$emit('loadspotlight', this.$route.params.id)
        },
        filterUpdate() {
            let testiFiltereds = this.testimonials;
            if (this.filtresValue.type !== 'All') {
                testiFiltereds = testiFiltereds.filter(testimonial => testimonial.type === this.filtresValue.type?.toLowerCase());
            }
            if (this.filtresValue.magnet) {
                testiFiltereds = testiFiltereds.filter(testimonial => {

                    return testimonial?.magnets?.id === this.filtresValue.magnet;
                });
            }
            if (this.filtresValue.rating?.length > 0) {
                testiFiltereds = testiFiltereds.filter(testimonial => this.filtresValue.rating.includes(testimonial.contact_note));
            }
            if (this.filtresValue.search) {
                testiFiltereds = testiFiltereds.filter(testimonial => {
                    return testimonial.contacts.name?.toLowerCase().includes(this.filtresValue.search?.toLowerCase()) ||
                        testimonial.contacts.email?.toLowerCase().includes(this.filtresValue.search?.toLowerCase()) ||
                        testimonial.contacts.job_title?.toLowerCase().includes(this.filtresValue.search?.toLowerCase()) ||
                        testimonial.magnets?.name?.toLowerCase().includes(this.filtresValue.search.toLowerCase())
                });
            }

            //display only testimonials with contact.email 
            testiFiltereds = testiFiltereds?.filter(testimonial => testimonial.contacts?.email);

            this.testimonialsFiltereds = testiFiltereds;
        },
        async deleteTestimonial(testimonial) {

            let { error } = await supabase
                .from('testimonials')
                .delete()
                .eq('id', testimonial.id);
            if (error) {
                this.error = error.message;
            } else {
                this.testimonials.splice(this.testimonials.indexOf(testimonial), 1);
                this.testimonialsFiltereds.splice(this.testimonialsFiltereds.indexOf(testimonial), 1);
            }
        },
        async deleteTestimonialConfirm(testimonial) {
            this.$r.dialogConfirm = {
                is: true,
                title: 'Delete story',
                message: 'Are you sure you want to delete this story ? This action cannot be undone.',
                callback: () => this.deleteTestimonial(testimonial)
            }
        }
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>
<style lang="scss" scoped>
.stories {

    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-template-rows: masonry;

}

.filters { 
    margin-bottom: 10px;
    .filter {
        max-width: 200px;
    }
}

.added {
    .testicard {
        border: 2px solid #7f56d9;
        box-shadow: 0 0 10px rgb(127, 86, 217, 0.4);
    }
}

.stories>div {
    break-inside: avoid;
}
</style>