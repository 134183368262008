<template>
    <div class="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Last updated: May 16, 2024</p>
        <p>
            Your privacy is important to us. It is skooad's policy to respect your privacy regarding any
            information we may collect from you across our website, https://skooad.fr/, and other sites we own and operate.
        </p>
        <h2>Information We Collect</h2>
        <p>
            We only collect information about you if we have a reason to do so, for example, to provide our Services, to
            communicate with you, or to improve our Services.
        </p>
        <h2>Cookies</h2>
        <p>
            We use cookies to help improve your experience of skooad. This cookie policy is part of skooad's privacy policy, and covers the use of cookies between your device and our site.
        </p>
        <h2>How We Use Information</h2>
        <p>
            We use the information we collect in various ways, including to provide, operate, and maintain our website,
            improve, personalize, and expand our website, understand and analyze how you use our website, develop new
            products, services, features, and functionality, and communicate with you.
        </p>
        <h2>Your Privacy Rights</h2>
        <p>
            You have the right to access, update, and delete your personal information. You can do so by contacting us
            at contact@skooad.fr.
        </p>
        <h2>Contact Us</h2>
        <p>If you have any questions about our privacy policy, please contact us at contact@skooad.fr.</p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>

<style scoped>

.privacy-policy {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    overflow: auto;
    margin-top: 70px;
    max-height: calc(100vh - 140px);
}

.privacy-policy h1,
.privacy-policy h2 {
    margin-top: 20px;
}

.privacy-policy p {
    margin: 10px 0;
}
</style>
