<template>
    <div class="w100 h100 d-flex jcc fdc aic">
        <div v-if="!loading" style="z-index: 1; width: 100%; max-width: 500px;">
            <h1 class="st mb-4">
                Thank you for your review !
            </h1>
            <TestimonialCard :testimonial="testimonial" />
        </div>
        <ImageStock class="wallpaper" :src="testimonial?.magnets?.wallpaper" />
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import ImageStock from '@/components/utils/ImageStock.vue';
import TestimonialCard from '@/components/TestimonialCard.vue';
export default {
    name: 'ShowTestimonial',
    created() {
        this.loadTestimonial()
    },
    components: {
        TestimonialCard,
        ImageStock
    },
    data() {
        return {
            testimonial: null,
            loading: false
        }
    },
    methods: {
        async loadTestimonial() {
            this.loading = true
            const { data, error } = await supabase
                .from('testimonials')
                .select('*,magnets(*)')
                .eq('id', this.$route.params.id)
            if (error) {
                console.error(error)
            } else {
                this.testimonial = data[0]
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.wallpaper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    :deep(img) {
        margin: 0;
    }
}
</style>