<template>
    <div id="my-widget" class="my-widget">
        <div class="type-floating" v-if="spotlight && spotlight.type == 'floating'">
            <div>
                <div class="testimonial testimonial-video" :style="placementTesti()" v-if="testiHover"
                    @mouseleave="testiHover = null">
                    <TestimonialCard @clickCTA="clickCTA" :autoplay="true" :testimonial="testiHover"
                        :fromSpotlight="true" :spotlight="spotlight" />
                </div>
                <div v-if="testimonials.length" class="bubbles" :class="spotlight.floating_position"
                    :style="{ 'background-color': 'rgba(0, 0, 0, 0.0)', 'z-index': `${spotlight.z_index}!important` }">
                    <div v-for="(testimonial, i) in testimonials" class="testi">
                        <Bubble :bubbleSize="bubbleSize" :testimonial="testimonial" :testiHover="testiHover"
                            @addView="$emit('addView')" @testiHover="changeTesti" :spotlight="spotlight" />
                    </div>

                    <div class="ad-logo-container"
                        @click="$r.openInNewTab('https://skooad.com'), $emit('addClickCTASkooad')"
                        :style="spotlight?.call_to_action_link ? { bottom: '-110px' } : ''"
                        v-if="spotlight?.show_skooad_brand">
                        <img class="ad-logo" src="https://app.skooad.com/skooad/public/assets/logoround.png" />

                    </div>
                </div>
                <div v-else-if="error" class="error">
                    <p>{{ error }}</p>
                    <p>
                        Visit the <a target="_blank" href="http://app.skooad.com/">Skooad dashboard</a> to configure
                        your
                        spotlight.
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TestimonialCard from '../components/TestimonialCard.vue';
import Bubble from './Bubble.vue';

export default {
    props: {
        editing: {
            type: Boolean,
            default: false,
        },
        domain: {
            type: String,
            default: null,
        },
        testimonials: {
            type: Array,
            default: [],
        },
        allTestimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TestimonialCard,
        Bubble,
    },
    data() {
        return {
            testiHover: null,
        };
    },
    created() {
        if (this.isMobile) {
            //set all testimonials at bottom
            this.spotlight.floating_position = 'bottom';
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            if (this.testiHover && !this.$el.contains(event.target)) {
                this.testiHover = null;
            }
        },
        placementTesti() {
            if (this.isMobile) {
                return {
                    bottom: this.getRightWithSize(),
                    //center the testimonial
                    left: '50%',
                    transform: 'translateX(-50%)',
                };
            }
            let i = this.testimonials.findIndex((testi) => testi.id == this.testiHover.id);
            if (this.spotlight.floating_position == 'top') {
                return {
                    top: this.getRightWithSize(),
                    left: event.clientX + 'px',
                    transform: 'translateX(-50%)',
                };
            } else if (this.spotlight.floating_position == 'bottom') {
                return {
                    bottom: this.getRightWithSize(),
                    left: event.clientX + 'px',
                    transform: 'translateX(-50%)',
                };
            } else if (this.spotlight.floating_position == 'left') {
                return {
                    top: `calc(50% + ${i * 50}px)`,
                    left: this.getRightWithSize(),
                    transform: 'translateY(-50%)',
                };
            } else {
                return {
                    top: `calc(50% + ${i * 50}px)`,
                    right: this.getRightWithSize(),
                    transform: 'translateY(-50%)',
                };
            }
        },
        clickCTA() {
            if (!this.editing) {
                this.$emit('addClickCTA');
            }
            this.$r.openInNewTab(this.spotlight?.call_to_action_link);
        },
        bubbleSize() {
            if (this.spotlight.bubbles_sizes == 'Small') {
                return '50px';
            } else if (this.spotlight.bubbles_sizes == 'Medium') {
                return '70px';
            } else {
                return '90px';
            }
        },
        getRightWithSize(size) {
            if (size == 'Small') {
                return '75px';
            } else if (size == 'Medium') {
                return '95px';
            } else {
                return '115px';
            }
        },
        playVideo(testimonial) {
            const videoUrl = `${testimonial.video}/`;
            const videoPlayer = document.createElement('video');
            videoPlayer.src = videoUrl;
            videoPlayer.controls = true;
            videoPlayer.autoplay = true;
            videoPlayer.style.position = 'fixed';
            videoPlayer.style.left = '50%';
            videoPlayer.style.top = '50%';
            videoPlayer.style.transform = 'translate(-50%, -50%)';
            videoPlayer.style.zIndex = '10000';
            videoPlayer.style.width = '80%';
            videoPlayer.style.height = '80%';
            document.body.appendChild(videoPlayer);

            videoPlayer.addEventListener('click', () => {
                document.body.removeChild(videoPlayer);
            });
        },
        changeTesti(testimonial) {
            this.testiHover = null
            //get index of testiHover in testimonials 
            this.$nextTick(() => {
                this.testiHover = testimonial;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(div),
:deep(p),
:deep(a),
:deep(span),
:deep(h1),
:deep(h2),
:deep(h3),
:deep(h4),
:deep(h5),
:deep(h6) {
    line-height: 22px !important;
}
</style>


<style lang="scss">
@import '../assets/scss/global.scss';
@import '../assets/scss/_variables.scss';
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css');

$taille-bulle : 50px;

.error {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5px 10px;
    border-radius: 10px;

    p {
        color: white;
    }

}

.ad-logo-container {
    right: 22px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
        cursor: pointer;
    }

    .ad-logo {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        opacity: 1 !important;
    }
}

.cta {
    height: $taille-bulle;
    width: $taille-bulle;
    font-size: $taille-bulle/5;
    right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffeeee;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    padding: 0 2px;

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
    }
}

.my-widget {

    .bubbles {
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        .img {
            width: $taille-bulle;
            height: $taille-bulle;
            border-radius: 100%;
            margin: 3px 0;

        }

        &.top {
            top: 10px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: row;
            width: fit-content;

            .testi {
                margin: 0 10px;
            }
        }

        &.bottom {
            height: fit-content;
            top: calc(100vh - 100px);
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: row;
            width: fit-content;

            .testi {
                margin: 0 10px;
            }
        }

        &.left {
            top: 50%;
            right: calc(100vw - 100px);
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            height: fit-content;

            .testi {
                margin: 10px 0;
            }
        }

    }

    .testimonial-video {
        position: fixed;
        right: 90px;
        z-index: 999;
        border-radius: 10px;
        overflow: visible !important;
        // top: calc(50% - 150px);
        width: fit-content;
        height: fit-content;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        video {
            height: 100%;
        }
    }

    .video-container {
        width: $taille-bulle;
        height: $taille-bulle;
        overflow: hidden;
        border-radius: 100%;
        margin: 10px 0;

        // video {
        //     width: auto;
        //     height: $taille-bulle;
        // }

    }

    .bubble.selected {
        transform: scale(1.1);
        transition: transform 0.5s;
    }
}
</style>
