<template>
    <div class="spotlight h100">
        <div class="spotlights-list d-flex aic mb-6" v-if="spotlights.length > 0">
            <div class="spotlight mr-4" v-for="spotlight in spotlights" :key="spotlight.id">
                <div class="head d-flex aic jcsb">
                    <div class="d-flex fdc jcfs">
                        <div class="left d-flex aic jcc mt-2" @click="actionHeader(spotlight.id)">
                            <h4>
                                {{ spotlight.name }}
                            </h4>
                            <div class="status ml-2"
                                :style="{ backgroundColor: spotlight.active ? '#4CAF50' : '#F44336' }" />
                        </div>
                        <v-chip size="small" color="primary" class="mr-2 wfc" style="transform: scale(0.9);">
                            {{ spotlight.type.charAt(0).toUpperCase() + spotlight.type.slice(1) }}
                        </v-chip>
                    </div>
                    <div class="right">
                        <v-menu offset-y :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                            rounded>
                            <template v-slot:activator="{ props }">
                                <v-btn icon v-bind="props" variant="text">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="actionHeader(spotlight.id)">
                                    <v-list-item-title>
                                        <v-icon color="primary">mdi-pencil</v-icon>
                                        Edit </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="activeDesactiveSpot(spotlight)">
                                    <v-list-item-title>
                                        <v-icon color="primary">
                                            {{ spotlight.active ? 'mdi-pause' : 'mdi-play' }}
                                        </v-icon>
                                        {{ spotlight.active ? 'Desactivate' : 'Activate' }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="deletespotlight(spotlight.id)">
                                    <v-list-item-title>
                                        <v-icon color="primary">mdi-delete</v-icon>
                                        Delete </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>
                <div class="bottom">
                    <div class="blocs d-flex jcsb">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <div class="stories ma-4 d-flex fdc aic" v-bind="props">
                                    <v-icon>
                                        mdi-eye-outline
                                    </v-icon>
                                    <p>
                                        {{ spotlight.views }} Views
                                    </p>
                                </div>
                            </template>
                            <span>Engagements are the number of times someone has hover on your spotlights to see
                                testimonials.</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <div class="stories ma-4 d-flex fdc aic" v-bind="props">
                                    <v-icon>
                                        mdi-cursor-default-click
                                    </v-icon>
                                    <p>
                                        {{ spotlight.clicks }} Engagements
                                    </p>
                                </div>
                            </template>
                            <span>Engagements are the number of times someone has clicked on your spotlights.</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <div class="stories ma-4 d-flex fdc aic" v-bind="props">
                                    <v-icon>
                                        mdi-percent-outline
                                    </v-icon>
                                    <p>
                                        {{ Math.ceil((spotlight.clicks / spotlight.views) * 100) }}% Engagement rate
                                    </p>
                                </div>
                            </template>
                            <span>Engagement rate is the percentage of engagements compared to the number of
                                views.</span>
                        </v-tooltip>
                    </div>
                    <div class="buttons ma-4 d-flex jce">
                        <v-btn @click="snippetDialog.spotlight = spotlight, snippetDialog.display = true"
                            color="primary" rounded="xl" elevation="0" class="mr-2 secondary">
                            <div class="mr-2">
                                <v-icon color="orange" v-if="!spotlight.in_a_website">
                                    mdi-alert-circle
                                </v-icon>
                                <!-- <v-icon color="green" v-else>
                                    mdi-check-circle
                                </v-icon> -->
                            </div>
                            <!-- <FluentEmoji class="mr-4" emojiName="warning" v-if="!spotlight.in_a_website" />
                            <FluentEmoji class="mr-4" emojiName="check-mark-button" v-else /> -->
                            {{ !spotlight.in_a_website ? 'Add in my website' : 'Added in my website' }}
                        </v-btn>
                        <v-btn @click="actionHeader(spotlight.id)" color="primary" rounded="xl" elevation="0">
                            Edit
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="info h100 w100 d-flex aic jcc fdc"
            @click="$router.push({ name: 'dashboard', params: { view: 'spotlights' } });">
            No spotlight yet, create one to start sharing your testimonials.
        </div>

        <!--  Add in my website dialog -->
        <v-dialog width="800" v-model="snippetDialog.display">
            <div class="dialog-share">
                <div class="head d-flex w100 jcc aic">
                    <FluentEmoji class="mr-4" size="50" emojiName="party-popper" />
                    <h1>
                        Add in my website
                    </h1>
                </div>
                <div class="body">
                    <div class="copy">
                        <div class="link">
                            <div class="mb-4 mt-4" v-if="snippetDialog.spotlight.type === 'carousel'">
                                <h4>You are ready to share your spotlight on your website.</h4>
                                <div class="info">
                                    Copy the code below and
                                    paste it where
                                    you want the carousel to appear on your website.
                                </div>
                            </div>
                            <div class="mb-4 mt-4" v-else>
                                <h4>
                                    You are ready to share your spotlight on your website.
                                </h4>
                                <div class="info">
                                    Copy the code below and paste it anywhere in your website's HTML code.
                                </div>
                            </div>
                            <div class="choose-html-or-php mb-4">
                                <v-btn color="primary" elevation="0" rounded="xl" class="mr-4"
                                    :variant="`${snippetDialog.type === 'html' ? 'flat' : 'outlined'}`"
                                    @click="snippetDialog.type = 'html'">
                                    HTML
                                </v-btn>
                                <v-btn color="primary" class="mr-4" elevation="0" rounded="xl"
                                    @click="snippetDialog.type = 'php'"
                                    :variant="`${snippetDialog.type === 'php' ? 'flat' : 'outlined'}`">
                                    PHP (Wordpress, Joomla, etc)
                                </v-btn>
                                <v-btn color="primary" elevation="0" class="mr-4" rounded="xl"
                                    @click="snippetDialog.type = 'vue'"
                                    :variant="`${snippetDialog.type === 'vue' ? 'flat' : 'outlined'}`">
                                    Vue, React, Angular
                                </v-btn>
                            </div>
                            <div class="link-input">
                                <div v-html="code"></div>

                                <v-icon @click="$r.copyToClipboard(getCodeReplace())">
                                    mdi-content-copy
                                </v-icon>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="domain">

                    </div>
                    <div class="bottom d-flex aic jcse">
                        <div class="left d-flex jcc fdc aic">
                            <h4 class="mb-4">
                                Share a QR code
                            </h4>
                          </div>
                        <div class="right d-flex jcc fdc">
                            <h4 class="mb-4">
                                Embed on your website
                            </h4>
                            <v-btn rounded="xl" elevation="0" color="primary" class="ml-4">
                                Copy code
                            </v-btn>
                        </div>
                    </div> -->
                </div>
                <!-- {{ shareDialog.magnet }} -->
            </div>
        </v-dialog>


        <!-- <div class="head">
            <h1>
                Templates
            </h1>
            <h2>
                Templates created to help you collect more, better quality testimonials.
            </h2>
        </div> -->
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import FluentEmoji from '@/components/utils/FluentEmoji.vue';
import QRcode from '@/components/utils/QRcode.vue';

export default {
    name: "spotlight",
    data() {
        return {
            spotlights: [],
            loading: false,
            snippetDialog: {
                display: false,
                snippet: '',
                type: 'html',
            },
        };
    },

    created() {
        if (!this.$r.spotlights) this.loadspotlights();
        else this.spotlights = this.$r.spotlights;
    },

    computed: {
        code() {
            // let div = '';
            // if (this.snippetDialog.spotlight.type === 'carousel') {
            //     div = `&lt;div id="skooad-widget" typewidget="carousel" class="skooad-widget" spotlight="${this.snippetDialog.spotlight.id}"&gt;&lt;/div&gt;`;
            // }
            // if (this.snippetDialog.type === 'html') {
            //     return `&lt;script src="https://app.skooad.com/skooad/public/widget-loader.js" spotlight="${this.snippetDialog.spotlight.id}" typewidget="${this.snippetDialog.spotlight.type}"&gt;&lt;/script&gt; ${div}`;
            // } else if (this.snippetDialog.type === 'php') {
            //     return `&lt;script src="https://app.skooad.com/skooad/public/widget-loader.js"
            //     spotlight="${this.snippetDialog.spotlight.id}" typewidget="${this.snippetDialog.spotlight.type}"&gt;&lt;/script&gt;
            //     ${div}`;
            // } else if (this.snippetDialog.type === 'vue') {
            //     // return `&lt;template&gt;&lt;div&gt;&lt;div typewidget="${this.snippetDialog.spotlight.type}" id="skooad-widget" class="skooad-widget" spotlight="${this.snippetDialog.spotlight.id}"&gt;&lt;/div&gt;&lt;/div&gt;&lt;/template&gt;&lt;script&gt;export default {mounted() {const script = document.createElement('script');script.src = 'https://app.skooad.com/skooad/public/widget-loader.js';document.getElementById('skooad-widget').appendChild(script);},}&lt;/script&gt; ${div}`;
            // } 
            let div = '';
            if (this.snippetDialog.spotlight.type === 'carousel') {
                div = `&lt;div id="skooad-widget" typewidget="carousel" class="skooad-widget" spotlight="${this.snippetDialog.spotlight.id}"&gt;&lt;/div&gt;`;
            }
            return `&lt;script src="https://app.skooad.com/skooad/public/widget-loader.js" spotlight="${this.snippetDialog.spotlight.id}" typewidget="${this.snippetDialog.spotlight.type}"&gt;&lt;/script&gt; ${div}`;
        }
    },

    components: {
        FluentEmoji,
        QRcode
    },

    methods: {
        getCodeReplace() {
            return this.code.replace(/<br>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        },
        async actionHeader(id) {
            this.$r.newSpotlight(id, this.$router)
        },
        async activeDesactiveSpot(spotlight) {
            spotlight.active = !spotlight.active
            await supabase.from('spotlights').update({ active: spotlight.active }).eq('id', spotlight.id)
        },
        async deletespotlight(id) {
            await supabase.from('spotlights').delete().eq('id', id)
            this.spotlights = this.spotlights.filter(spotlight => spotlight.id !== id);
        },
        async loadspotlights() {
            const response = await supabase.from('spotlights').select().eq('user', this.$r.user.id);
            this.spotlights = response.data
        },
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.spotlight {
    margin-top: 10px;

    .template {
        border-bottom: 1px solid #E8DEF8;
    }

    .better-stories {
        border: 1px solid #E8DEF8;
        border-radius: 10px;
        width: fit-content;
        padding: 1rem;

    }


    .spotlights-list {
        max-width: calc(100% - 0px);
        overflow-x: auto;

        .spotlight {
            width: 400px;
            border-radius: 10px;
            border: 1px solid #E8DEF8;

            .head {
                padding: 5px 15px;

                .left {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .status {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }

            .bottom {
                padding: 5px 10px;

                .blocs {
                    font-size: 14px;
                    white-space: nowrap;
                }
            }
        }
    }
}

.dialog-share {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;
    width: 800px;

    .body {
        .copy {
            .link {
                .link-input {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #E8DEF8;
                    border-radius: 5px;
                    padding: 10px 10px;
                    font-size: 14px;

                    v-icon {
                        cursor: pointer;
                    }
                }
            }
        }

        .bottom {
            padding: 20px;
            margin-top: 20px;
            border-radius: 5px;
            background-color: #F3EDF7;

        }
    }
}
</style>
