<template>
    <div class="form-user inscription d-flex aic ">
        <div class="left-container">
            <div class="left">
                <img :src="require(`@/assets/logo.png`)" alt="Logo de Skooad" style="width: 150px;" />
                <h2>

                    Welcome to Skooad
                </h2>
                <p>
                    Skooad is an application that allows you to collect the opinions of your customers on your products
                    and services, then share them with the whole world.
                </p>
                <div class=" formulaire mt-6 d-flex fdc aic">
                    <!-- <GoogleLogin class="mb-2" :callback="registerWithGoogle" /> -->
                    <v-btn @click="registerWithGoogle" class="mb-4" elevation="0" color="primary" outlined
                        style="width: fit-content;">
                        <v-icon class="mr-2">mdi-google-plus</v-icon>
                        Log in or register with Google</v-btn>
                    <div class="d-flex aic w100">
                        <v-form @submit="registerWithEmail" class="form" v-if="register">
                            <v-text-field density="compact" v-model="email" label="Email" type="email"
                                variant="outlined" class="mt-4" required></v-text-field>
                            <v-text-field v-model="password" label="Password" type="password" density="compact"
                                variant="outlined" class="mt-4" required></v-text-field>
                            <v-btn type="submit" color="primary" elevation="0" class="mx-auto pr-8 pl-8">
                                Register
                            </v-btn>
                            <p class="mt-4">
                                Already have an account ? <a @click="register = false">Login</a>

                            </p>
                        </v-form>
                        <v-form v-else @submit="loginWithEmail" class="form">
                            <v-text-field density="compact" variant="outlined" v-model="email" label="Email"
                                type="email" class="mt-4" required></v-text-field>
                            <div class="reset-password-container">
                                <a @click="$router.push('/reset-password')" class="reset-password">Forgot your password
                                    ?</a>
                            </div>
                            <v-text-field density="compact" variant="outlined" v-model="password" label="Password"
                                type="password" class="mt-4" required></v-text-field>
                            <v-btn type="submit" color="primary" elevation="0" class="mx-auto pr-8 pl-8">
                                Connect
                            </v-btn>
                            <p class="mt-4">
                                You don't have an account ? <a @click="register = true">Register</a>
                            </p>
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="right" :style="{ backgroundImage: `url(${require(`@/assets/registerIllustration.png`)})` }">
            <!-- <img :src="require(`@/assets/registerIllustration.png`)" alt="Illustration d'inscription" /> -->
        </div>
    </div>
</template>

<script>
import { supabase } from '../../supabase';
import { GoogleLogin } from 'vue3-google-login';

export default {
    name: 'login',
    components: {
        GoogleLogin,
    },
    data() {
        return {
            email: '',
            password: '',
            register: true,
            gopro: false
        };
    },
    created() {
        this.gopro = this.$route.query.gopro == 'true';
        if (this.$r.user && !this.gopro) {
            this.$r.setMessage('info', 'Vous êtes déjà connecté');
            this.$router.push('/');
        } else if (this.$r.user && this.gopro) {
            this.$router.push('/payment-form');
        }
    },
    methods: {
        testPro() {
            if (this.gopro) {
                this.$router.push('/payment-form')
            } else this.$router.push('/');
        },
        async registerWithGoogle(response) {
            let url = this.$r.config.domain
            let redirect = this.gopro ? `${url}payment-form` : url;
            try {
                const { data, error } = await supabase.auth.signInWithOAuth({
                    provider: 'google',
                    options: {
                        redirectTo: redirect
                    }
                });

                if (error) throw error;


                this.$r.user = data.user;
                this.testPro()
            } catch (error) {
                console.error('Error logging in with Google:', error);
            }
        },
        async registerWithEmail() {
            try {
                const response = await supabase.auth.signUp({
                    email: this.email,
                    password: this.password,
                });

                this.$r.user = response.data.user
                this.testPro()
            } catch (error) {

                console.error('Error registering with Email:', error);
            }
        },
        async loginWithEmail() {
            try {
                const response = await supabase.auth.signInWithPassword({
                    email: this.email,
                    password: this.password,
                });

                this.$r.user = response.data.user
                this.testPro()
            } catch (error) {
                console.error('Error logging in with Email:', error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.inscription {
    padding: 0;
}

.reset-password-container {
    height: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    transform: translateY(-5px);

    .reset-password {
        font-size: 12px;
    }
}

.mobile {
    .left-container {
        width: 100%;
    }

    .right {
        display: none;
    }
}

.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;

    .left {
        max-width: 700px;
        padding: 0 2rem;
    }
}

.right {
    width: 40%;
    height: 100vh;
    background-size: cover;
}

button {
    width: 100%;
}

:deep(.g-btn-wrapper) {
    iframe {
        width: 100% !important;
        height: 50px !important;
        margin: 0 !important;
    }
}

.form {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .v-input {
        width: 100%;
    }

}
</style>
