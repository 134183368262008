<template>
    <img :src="emojiSrc" :style="{
        width: `${size}px`, height: `${size}px`, filter: baw ? 'grayscale(100%)' : '',
    }" :alt="emojiName" class="fluent-emoji mr-2" />
</template>

<script>
export default {
    props: {
        emojiName: {
            type: String,
            required: true,
        },
        styleType: {
            type: String,
            default: 'color', // other possible types could be 'flat', 'high-contrast', etc.
        },
        size: {
            type: Number,
            default: 25, // default size
        },
        baw: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        emojiSrc() {
            // Construct the URL based on the emoji name and style type
            return `https://cdn.jsdelivr.net/gh/mkabumattar/fluentui-emoji@latest/icons/flat/${this.emojiName}.svg`;
        },
    },
};
</script>

<style scoped>
.fluent-emoji {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    opacity: 1 !important;
}
</style>
