<template>
    <div class="d-flex">
        <div class="left">
            <v-icon class="mt-4 ml-4 mb-4" @click="$router.go(-1)">mdi-arrow-left</v-icon>
            <FicheInfos :contact="contact" @updateContact="reloadTesti" />
        </div>
        <div class="right">
            <div class="stories w100" v-if="testimonials.length">
                <div v-for="testimonial in testimonials" :key="testimonial.id" class="mb-4 d-flex jcc">
                    <TestimonialCard :autoplay="false" :testimonial="testimonial" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase';
import FicheInfos from './FicheInfos.vue'
import TestimonialCard from '../../components/TestimonialCard.vue'

export default {
    name: 'Contact',
    components: {
        FicheInfos,
        TestimonialCard
    },
    data() {
        return {
            contact: null,
            testimonials: []
        }
    },
    created() {
        this.getContact(this.$route.params.id)
    },
    methods: {
        async reloadTesti() {
            this.testimonials = []
            await this.getTestis(this.contact.id)
        },
        async getContact(id) {
            const { data, error } = await supabase
                .from('contacts')
                .select('*, testimonials(*)')
                .eq('id', id)

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                this.contact = data[0]
            }
            await this.getTestis(id)
        },
        async getTestis(id) {
            const { data, err } = await supabase
                .from('testimonials')
                .select('*, contacts(*)')
                .eq('contact', id)
            this.testimonials = data
            this.testimonials.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            })

        }
    },
}
</script>

<style scoped lang="scss">
.left {
    width: 400px;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
}

.right {
    width: calc(100% - 400px);
    background-color: #fcfcfd;
    padding: 20px;
    overflow-y: auto;


    .stories {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-template-rows: masonry;

    }
}
</style>