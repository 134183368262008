<template>
    <div>
        <!-- <script src="https://app.skooad.com/skooad/public/widget-loader.js"></script> -->
        <div id="skooad-widget" class="skooad-widget" spotlight="a7d0bb06-98ef-4bca-9ff5-e2ec1e432e02"></div>
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://app.skooad.com/skooad/public/widget-loader.js'; 
        script.spotlight = 'a7d0bb06-98ef-4bca-9ff5-e2ec1e432e02';
        document.getElementById('skooad-widget').appendChild(script);
    },
}
</script>