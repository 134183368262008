<template>
    <div class="testicard" @click="clickCard()" ref="testicard" :class="{
        'added-spot': spotlightBtns && testimonial.added,
        'notadded-spot': spotlightBtns && !testimonial.added
    }" id="testicard" :style="{
        '--testimonial-font': spotlight?.font || 'DM sans'
    }">

        <div class="company-photo-container" v-if="!spotlight || spotlight?.show_contact_company_logo">
            <div class="company-photo">
                <ImageStock width="60px" height="60px" :src="testimonial.contacts?.company_logo" :rounded="true"
                    :alt="testimonial.contacts?.name" />
            </div>
        </div>
        <!-- VIDEO -->
        <div v-if="testimonial.type == 'video'">
            <div class="top d-flex jcc fdc aic"
                :style="`${spotlight ? 'border-bottom-left-radius: 10px; overflow:hidden; border-bottom-right-radius: 10px;' : ''}`">
                <div class="stars-container" v-if="(!spotlight || spotlight?.show_rating) && testimonial.contact_note">
                    <NoteStar class="stars" v-if="testimonial.contact_note" :note="testimonial.contact_note" />
                </div>
                <div class="video">
                    <ImageStock :color="spotlight?.color" @videoLoaded="$emit('setHeight')"
                        :desactivePlay="!spotlightBtns" :thumbnail="testimonial.thumbnail" :autoplay="autoplay"
                        :src="testimonial.video" :testimonial="true" :alt="testimonial.contacts?.name" />
                </div>

                <div class="infos-container d-flex aic jcsb w100">
                    <div class="aic">
                        <ImageStock v-if="!spotlight || spotlight.show_contact_photo" width="60px" height="60px"
                            :src="testimonial.contacts?.photo" :rounded="true" :alt="testimonial.contacts?.name" />
                        <div class="infos">
                            <!-- name -->
                            <h3 v-if="testimonial.contacts?.name">
                                {{ testimonial.contacts?.name }}
                            </h3>
                            <!-- Job title  -->
                            <h4 v-if="testimonial.contacts?.job_title">
                                {{ testimonial.contacts?.job_title }}
                            </h4>
                            <!-- Company name -->
                            <h4 v-if="testimonial.contacts?.company_name">
                                {{ testimonial.contacts?.company_name }}
                            </h4>
                        </div>
                    </div>
                    <div class="call-to-action-container d-flex aic jcc mb-4" v-if="spotlight?.type == 'floating'">
                        <v-btn rounded="xl" @click="$emit('clickCTA')"
                            v-if="spotlight?.show_call_to_action && spotlight?.call_to_action_text" class="mt-2"
                            :color="spotlight.color || primary">
                            {{ spotlight?.call_to_action_text }}
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="bottom mt-4 ml-2 d-flex aic jcsb" v-if="!spotlight">
                <div class="created_at info" v-if="testimonial.created_at">
                    {{ $r.timefrom(testimonial.created_at) }}
                </div>
                <v-menu v-if="(deleteBtn || spotlightBtns) && !fromSpotlight" offset-y :close-on-content-click="false"
                    :nudge-right="40" transition="scale-transition" rounded>
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                        <v-list-item v-if="deleteBtn && !spotlightBtns"
                            @click="$emit('deleteTestimonial', testimonial)">
                            <v-list-item-title>
                                <v-icon color="primary">mdi-delete</v-icon>
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="spotlightBtns && !testimonial.added"
                            @click.stop="$emit('addTestimonial', testimonial)">
                            <v-list-item-title>
                                <v-icon color="primary">mdi-star</v-icon>
                                Add in spotlight
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-else-if="spotlightBtns && testimonial.added"
                            @click="$emit('removeTestimonial', testimonial.id)">
                            <v-list-item-title>
                                <v-icon color="primary">mdi-star-off</v-icon>
                                Remove from spotlight
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <!-- TEXT -->
        <div v-else-if="testimonial.type == 'text'" class="text-testimonial">
            <!-- NOTE -->
            <NoteStar class="stars mb-2" v-if="testimonial.contact_note && (!spotlight || spotlight?.show_rating)"
                :note="testimonial.contact_note" />

            <!-- REPONSE -->
            <div class="answers" :class="`${!spotlight?.show_rating ? 'mt-4' : ''}`">
                <div v-for="answer in testimonial.text_answers" v-if="typeof testimonial.text_answers == 'object'"
                    :key="answer.id" class="question-answer">
                    <div class="question">
                        {{ answer.question }}
                    </div>
                    <div class="answer">
                        {{ answer.answer }}
                    </div>
                </div>
                <div v-else>
                    {{ testimonial.text_answers }}
                </div>
            </div>

            <!-- CONTACT -->
            <div class="d-flex aic w100 jcsb">
                <div class="aic">
                    <ImageStock width="60px" height="60px" :src="testimonial.contacts?.photo"
                        v-if="testimonial.contacts?.photo && (!spotlight || spotlight.show_contact_photo)"
                        :rounded="true" :alt="testimonial.contacts?.name" />
                    <div v-else class="ml-1" style="height: 80px;" />
                    <!-- <img v-else-if="!spotlight || spotlight.show_contact_photo" class="ma-2"
                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        style="width: 60px!important; height: 60px!important;" alt="user" /> -->
                    <div class="infos black">
                        <!-- name -->
                        <h3 v-if="testimonial.contacts?.name">
                            {{ testimonial.contacts?.name }}
                        </h3>
                        <!-- Job title  -->
                        <h4 v-if="testimonial.contacts?.job_title">
                            {{ testimonial.contacts?.job_title }}
                        </h4>
                        <!-- Company name -->
                        <h4 v-if="testimonial.contacts?.company_name">
                            {{ testimonial.contacts?.company_name }}
                        </h4>
                    </div>
                </div>
                <div class="call-to-action-container d-flex aic jcc mb-4" v-if="spotlight?.type == 'floating'">
                    <v-btn rounded="xl" @click="$emit('clickCTA')"
                        v-if="spotlight?.show_call_to_action && spotlight?.call_to_action_text" class="mt-2"
                        :color="spotlight.color || primary">
                        {{ spotlight?.call_to_action_text }}
                    </v-btn>
                </div>

            </div>
            <div class="d-flex aic w100 jcsb">
                <div class="created_at info ml-1" v-if="testimonial.created_at">
                    {{ $r.timefrom(testimonial.created_at) }}
                </div>

                <!-- ACTIONS-->
                <div class="bottom mt-4 ml-2 mb-1 d-flex aic jcfe"
                    v-if="!spotlight && ((deleteBtn || spotlightBtns) && !fromSpotlight)">
                    <v-menu offset-y :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        rounded>
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list>
                            <v-list-item v-if="deleteBtn && !spotlightBtns"
                                @click="$emit('deleteTestimonial', testimonial)">
                                <v-list-item-title>
                                    <v-icon color="primary">mdi-delete</v-icon>
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="spotlightBtns && !testimonial.added"
                                @click="$emit('addTestimonial', testimonial)">
                                <v-list-item-title>
                                    <v-icon color="primary">mdi-star</v-icon>
                                    Add in spotlight
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else-if="spotlightBtns && testimonial.added"
                                @click="$emit('removeTestimonial', testimonial.id)">
                                <v-list-item-title>
                                    <v-icon color="primary">mdi-star-off</v-icon>
                                    Remove from spotlight
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ImageStock from './utils/ImageStock.vue';
import NoteStar from './utils/NoteStar.vue';
import { supabase } from '../supabase';
export default {
    props: {
        testimonial: {
            type: Object,
            default: null,
        },
        spotlightBtns: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        deleteBtn: {
            type: Boolean,
            default: false
        },
        addBtn: {
            type: Boolean,
            default: false
        },
        fromSpotlight: {
            type: Boolean,
            default: false
        },
        spotlight: {
            type: Object,
            default: null
        }
    },
    components: {
        ImageStock,
        NoteStar
    },
    async mounted() {
        if (this.testimonial.type == 'text') {
            await this.getQuestions();
        }
        if (this.spotlight?.font) {
            // document.getElementById('testicard').style.fontFamily = this.spotlight.font
            await this.setFont();
        }
    },
    methods: {
        async clickCard() {
            if (this.spotlightBtns) {
                if (this.testimonial.added) {
                    this.$emit('removeTestimonial', this.testimonial.id);
                } else {
                    this.$emit('addTestimonial', this.testimonial);
                }
            }
        },
        async getQuestions() {
            if (this.testimonial.magnets?.id) {
                let res = await supabase.from('questions').select('*').eq('magnet', this.testimonial.magnets?.id);

                this.testimonial.text_answers?.map((item) => {
                    item.question = res.data?.find(question => question.id == item.question_id)?.question
                });
            }
            return;
        },
        setFont() {
            this.$refs.testicard.style.fontFamily = this.spotlight?.font ? this.spotlight.font : 'DM sans';
        }
    }
}
</script>

<style scoped lang="scss">
* {
    //variable of vue
    font-family: var(--testimonial-font) !important;
    text-transform: none !important;
}



.info {
    color: #28343a !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
    opacity: 0.8;
}

.mobile{
    .testicard{
        width: 350px;
    }
}

.testicard {
    border-radius: 10px;
    width: 400px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
    // border: 1px solid rgba(0, 0, 0, 0.1);

    .company-photo-container {
        .company-photo {
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            margin-right: 25px;
            justify-content: right;
            align-items: center;
            // background-color: #fff;
        }

        z-index: 10;
        position: relative;
        transform: translateY(-30px);

        :deep(img) {
            margin: 0;
        }

        height: 0px;
    }

    &.added-spot,
    &.notadded-spot {
        cursor: pointer;
    }

    &.notadded-spot {
        &:hover {
            transition: all 0.5s;
            transform: scale(1.03);
        }
    }

    &.added-spot {
        &:hover {
            transition: all 0.5s;
            transform: scale(0.97);
        }
    }

    .text-testimonial {
        padding: 10px;

        .answers {
            padding: 0 5px;
            line-height: 22px !important;

            .question-answer {
                padding: 0 0px 5px 0px;

                .question {
                    font-size: 0.8rem;
                    font-weight: 600;
                    margin: 0;
                    color: #000;
                }

                .answer {
                    font-weight: 400;
                    margin: 0;
                    color: #000;
                }
            }
        }
    }

    .call-to-action-container {}

    .stars-container {
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .stars {
            padding-left: 10px;
            transform: translateY(10px);
            height: 0px;
        }
    }

    .bottom {
        padding: 10px;
        padding-top: 0;

        color: #28343a !important;
        font-weight: 500 !important;
        font-size: 0.9rem !important;
        opacity: 0.8;
    }

    :deep(video) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        // max-height: 500px;
    }

    .answers {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 0.95rem;
    }

    .infos {
        text-align: left;
        &.black {
            color: #000 !important;

            h3,
            h4,
            p {
                color: #000 !important;
            }
        }

        h3 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            line-height: 22px !important;
        }

        h4 {
            font-size: 0.8rem;
            font-weight: 400;
            margin: 0;
            line-height: 22px !important;
        }
    }

    .infos-container {
        margin: 10px 0;
        width: 100%;
        display: flex;
        color: black;
        padding-left: 15px;
        padding-right: 15px;

    }
}
</style>

<style lang="scss">
.vueperslides__arrow--next {
    transform: translateX(20px) translateY(-50%) !important;
}

.vueperslides__arrow--prev {
    transform: translateX(-12px) translateY(-50%) !important;
}
</style>