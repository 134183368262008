<template>
    <div class="d-flex jcc fdc aic h100">
        Validation du paiement, veuillez patienter...
        <v-progress-circular class="mt-4" indeterminate color="primary" />
    </div>
</template>

<script>
import { supabase } from '../supabase'
export default {
    created() {
        this.verifyPayment()
    },
    methods: {
        async verifyPayment() {
            //get auth token
            const { data: { session }, error } = await supabase.auth.getSession();
            let body = {
                sessionId: this.$route.query.session_id,
                userId: this.$r.user.id,
                acessToken: session.access_token
            }
            const response = await fetch(`${this.$r.config.service_video}payements/verify-payment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                }
            )
            if (response.status !== 200) {
                let user = await supabase.auth?.getSession();
                this.$r.user = user?.data?.session?.user;
                this.$r.setMessage('error', 'Error during payment validation')
                this.$router.push({ name: 'dashboard' })
            } else {
                this.$r.user.informations.pro = true
                this.$r.setMessage('success', 'Payment validated')
                this.$router.push({ name: 'dashboard' })
            }
        }
    }
}
</script>