<template>
    <div class="form">
        <div class="container" v-if="!loading">
            <div class="header">
                <div class="logo-container-container">
                    <div class="logo-container">
                        <img class="logo" :src="require(`@/assets/logoentreprise.png`)" alt="Logo de Skooad" />
                    </div>
                </div>
                <div class="button-container-container">
                    <div class="button-container">
                        <v-btn elevation="0" rounded="xl" size="x-small" color="primary"
                            @click="$r.openInNewTab('https://skooad.com/')">
                            Get cool testimonials with SKOOAD >
                        </v-btn>
                    </div>
                </div>
            </div>

            <h2>Review</h2>

            <div>
                <video :src="`${domain}/${id}.webm`" controls></video>
                <div class="d-flex aic jcc w100">
                    <div class="mr-2">
                        <v-icon color="primary">mdi-download</v-icon>
                        <a :href="`${domain}/${id}.webm`" download>Download</a>
                    </div>
                    <div>
                        <v-icon color="primary">mdi-share-variant</v-icon>
                        <a :href="`${domain}/${id}.webm`" target="_blank">Share</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Review',

    data() {
        return {
            id: this.$route.params.id,
            domain: window.location.origin + '/uploads'
        }
    }
}
</script>

<style scoped lang="scss">
.mobile {
    .container {
        padding: 0 0.5rem !important;
        box-shadow: none !important;
        border: none !important;

        .button-container {
            left: -260px !important;
        }
    }

}

video {
    border-radius: 10px;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}

.form {
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .container {
        max-width: 650px;
        min-height: 400px;
        margin: 0 auto;
        padding: 3rem;
        background-color: white;
        border-radius: 10px;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #f1f1f1;


        .header {
            display: flex;
            justify-content: space-between
        }

        .button-container-container {
            width: 0;
            height: 0;
        }

        .button-container {
            position: relative;
            top: -58px;
            left: -255px;
            width: 270px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 100%;
            }
        }

        .logo-container-container {
            width: 0;
            height: 0;
        }

        .logo-container {
            background-color: white;
            border-radius: 50%;
            position: relative;
            top: -90px;
            left: -10px;
            width: 85px;
            height: 85px;
            overflow: hidden;
            border: 1px solid #f1f1f1;

            .logo {
                width: 100%;
                height: 100%;
            }
        }
    }

    .progress-bar-container {
        margin-top: 20px;
        width: 300px;
        height: 10px;
        background-color: #607d8b9c;
        display: flex;
        border-radius: 5px;
        overflow: hidden;

        .progress-bar {
            background-color: #de6348;
        }
    }
}
</style>