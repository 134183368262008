<template>
    <div>
        <div id="carousel">
            <Carousel :editing="editing" @addView="$emit('addView')" @addClickCTA="$emit('addClickCTA')"
                @addClickCTASkooad="$emit('addClickCTASkooad')" :testimonials="testimonials" :spotlight="spotlight" />
        </div>
    </div>
</template>

<script>
import Carousel from '../components/Carousel.vue';

export default {
    props: {
        testimonials: {
            type: Array,
            default: [],
        },
        spotlight: {
            type: Object,
            default: null,
        },
        testing: {
            type: Boolean,
            default: null,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Carousel
    },
    mounted() {
        this.moveCarousel()
    },
    methods: {
        moveCarousel() {
            if (!this.testing) {
                try {
                    const carousel = document.getElementById('carousel');
                    let widgetSkooad = document.getElementById('skooad-widget');
                    document.body.insertBefore(carousel, widgetSkooad);
                } catch (e) {
                    // console.log(e);
                }
            }  
        }
    }
};
</script>
