<template>
    <div>
        <div v-for="item in news" :key="item.id">
            <div class="mt-4">
                <h2>{{ item.version }}</h2>
                <p v-html="item.description"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Whatsnew',
    data() {
        return {
            news: []
        }
    },
    props: {
        view: String
    },
    async created() {
        console.log('Whatsnew created')
        this.news = await this.$r.getValuesFromTable('whatsnew', null, null, null, true)
        //sort by version
        this.news.sort((a, b) => {
            return a.version > b.version ? -1 : 1
        })
    }
}
</script>