<template>
    <v-dialog v-model="$r.createStory.is" max-width="390" content-class="dialog-confirm">
        <v-card class="card pa-4 pt-6 pb-6">
            <!-- {{ createStory }} -->
            <h4 class="mb-2 mt-4">
                Create a story
            </h4>
            <div v-if="step === 1">
                <div class="info mb-4">
                    Choose the type of story you want to create.
                </div>
                <v-btn rounded="xl" elevation="0" class="mb-4 button-primary" style="width:calc(100% - 20px);" @click="
                    createStory.type = 'video', step = 2">Video</v-btn>

                <v-btn rounded="xl" elevation="0" class="mb-4 button-secondary" style="width: calc(100% - 20px);"
                    @click="createStory.type = 'text', step = 2">Text</v-btn>
            </div>

            <div v-else-if="step === 2 && createStory.type === 'video'">
                <div class="info mb-4">
                    Pick a rating or leave it blank, then upload your video.
                </div>

                <Input :input="ratingInput" :object="createStory" :value="createStory.rating" />

                <Input :input="videoInput" :object="createStory" :value="createStory.video" />

                <div class="buttons d-flex jcsb aic mt-4">
                    <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(50% - 20px);"
                        @click="step = 1">Back</v-btn>

                    <v-btn rounded="xl" elevation="0" class="button-primary" style="width: calc(50% - 20px);"
                        @click="step = 3">Next</v-btn>
                </div>
            </div>

            <div v-else-if="step === 2 && createStory.type === 'text'">
                <div class="info mb-4">
                    Pick a rating or leave it blank, then write your story.
                </div>

                <Input :input="ratingInput" :object="createStory" :value="createStory.rating" />

                <Input :input="textInput" :object="createStory" :value="createStory.text" />

                <div class="buttons d-flex jcsb aic mt-4">
                    <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(50% - 20px);"
                        @click="step = 1">Back</v-btn>

                    <v-btn rounded="xl" elevation="0" class="button-primary" style="width: calc(50% - 20px);"
                        @click="step = 3">Next</v-btn>
                </div>
            </div>

            <!-- Associate a contact -->
            <div v-else-if="step === 3">
                <div class="info mb-4">
                    Link your story to an existing contact or add to a new one.
                </div>
                <v-btn rounded="xl" elevation="0" class="button-primary mb-4" style="width: calc(100% - 20px);"
                    @click="createStory.contact = { exist: true, id: null }, step = 4">Add to a existing
                    contact</v-btn>

                <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(100% - 20px);"
                    @click="createStory.contact = { exist: false }, step = 4">Add to a new contact</v-btn>
            </div>

            <!-- Add to a existing contact -->
            <div v-else-if="step === 4 && createStory.contact.exist">
                <div class="info mb-4">
                    Search for a contact to link your story to.
                </div>
                <Input :input="filterContacts" :object="createStory.contact" :value="createStory.contact.search" />

                <div class="buttons d-flex jcsb aic mt-4">
                    <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(50% - 20px);"
                        @click="step = 3">Back</v-btn>

                    <v-btn rounded="xl" elevation="0" class="button-primary" style="width: calc(50% - 20px);"
                        @click="submit">Submit</v-btn>
                </div>
            </div>

            <!-- Add to a new contact -->
            <div v-else-if="step === 4 && !createStory.contact.exist">
                <div class="info mb-4">
                    Fill in the details of the new contact.
                </div>
                <Input :input="nameInput" :object="createStory.contact" :value="createStory.contact.name" />

                <Input :input="emailInput" :object="createStory.contact" :value="createStory.contact.email" />
                <Input :input="photoInput" :object="createStory.contact" :value="createStory.contact.company_logo" />

                <Input :input="jobTitleInput" :object="createStory.contact" :value="createStory.contact.job_title" />

                <Input :input="websiteInput" :object="createStory.contact" :value="createStory.contact.website" />

                <Input :input="companyNameInput" :object="createStory.contact"
                    :value="createStory.contact.company_name" />

                <Input :input="companyLogoInput" :object="createStory.contact"
                    :value="createStory.contact.company_logo" />

                <div class="buttons d-flex jcsb aic mt-4">
                    <v-btn rounded="xl" elevation="0" class="button-secondary" style="width: calc(50% - 20px);"
                        @click="step = 3">Back</v-btn>

                    <v-btn rounded="xl" elevation="0" class="button-primary" style="width: calc(50% - 20px);"
                        @click="submit">Submit</v-btn>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { supabase } from '@/supabase'
import Input from '@/components/inputs/Input.vue';

export default {
    name: "CreateAStory",
    data() {
        return {
            step: 1,
            filterContacts: {
                name: 'Search for a contact',
                type: 'dropdown',
                update: false,
                model: 'id',
                options: null
            },
            createStory: {
                is: false,
                type: null,
                video: null,
                rating: null,
                text: null,
                contact: null,
                type: null
            },
            ratingInput: {
                name: 'Rating',
                type: 'stars',
                update: false,
                model: 'rating'
            },
            videoInput: {
                name: 'Upload a video',
                type: 'video',
                update: false,
                model: 'video',
                directory: 'public/uploads/'
            },
            textInput: {
                name: 'Write your story',
                type: 'textarea',
                update: false,
                model: 'text'
            },
            nameInput: {
                name: 'Name',
                type: 'text',
                update: false,
                model: 'name'
            },
            emailInput: {
                name: 'Email',
                type: 'text',
                update: false,
                model: 'email'
            },
            photoInput: {
                name: 'Photo',
                type: 'image',
                update: false,
                model: 'photo'
            },
            jobTitleInput: {
                name: 'Job title',
                type: 'text',
                update: false,
                model: 'job_title'
            },
            websiteInput: {
                name: 'Website',
                type: 'text',
                update: false,
                model: 'website'
            },
            companyNameInput: {
                name: 'Company name',
                type: 'text',
                update: false,
                model: 'company_name'
            },
            companyLogoInput: {
                name: 'Company logo',
                type: 'image',
                update: false,
                model: 'company_logo'
            }
        }
    },
    created() {
        this.filterContacts.options = this.$r.contacts.map(contact => {
            return {
                value: contact.id,
                text: contact.name
            }
        })

    },
    components: {
        Input
    },
    methods: {
        async submit() {
            let contact
            if (!this.createStory.contact.exist) {
                contact = await supabase.from('contacts').insert({
                    user: this.$r.user.id,
                    email: this.createStory.contact.email,
                    photo: this.createStory.contact.photo,
                    job_title: this.createStory.contact.job_title,
                    website: this.createStory.contact.website,
                    company_name: this.createStory.contact.company_name,
                    company_logo: this.createStory.contact.company_logo,
                    name: this.createStory.contact.name,
                    // video: this.createStory.video
                }).select('id').single()
            } 
            let res = await supabase.from('testimonials').insert({
                user: this.$r.user.id,
                contact_note: this.createStory.rating,
                magnet: this.createStory.magnet,
                contact: this.createStory.contact.exist ? this.createStory.contact.id : contact.data.id,
                type: this.createStory.type,
                text_answers: this.createStory.text,
                video: this.createStory.video
            })

            this.$emit('loadStories')
            this.$emit('reloadView')
            this.$r.createStory.is = false
        }
    }
}
</script>