<template>
    <div class="d-flex w100">
        <div class="left-container">
            <div class="left d-flex fdc aic">
                <div class="d-flex aic fdc w100" v-if="!sent">
                    <v-text-field v-model="email" label="Email" variant="outlined" class="mt-4" required></v-text-field>
                    <v-btn @click="sendPasswordResetEmail" color="primary" elevation="0" class="mx-auto">
                        Send password reset email
                    </v-btn>
                </div>
                <div class="d-flex aic fdc" v-else>
                    <v-alert type="success" class="mt-4">
                        An email has been sent to you with a link to reset your password.
                    </v-alert> 
                </div>
            </div>
        </div>

        <div class="right" :style="{ backgroundImage: `url(${require(`@/assets/registerIllustration.png`)})` }" />
    </div>
</template>

<script>
import { supabase } from '@/supabase';
export default {
    name: 'login',
    data() {
        return {
            email: '',
            sent: false
        }
    },
    methods: {
        async sendPasswordResetEmail() {
            const { data, error } = await supabase.auth.resetPasswordForEmail(this.email, {
                redirectTo: `${this.$r.config.domain}update-password`,
            })
            this.sent = true
        }
    }
}
</script>

<style lang="scss" scoped>
.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;

    .left {
        width: calc(100% - 40px);

        :deep(.v-input) {
            width: 100%;
            max-width: 400px;
        }
    }
}

.right {
    width: 40%;
    height: 100vh;
    background-size: cover;
}
</style>
