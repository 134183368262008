<template>
    <div class="magnet d-flex">
        <div class="left" v-if="!loading">
            <div class="head">
                <div class="back">
                    <v-icon class="mb-4" @click="returnBack()">
                        mdi-arrow-left
                    </v-icon>
                </div>

                <div class="title d-flex aic mb-6">
                    <h1 v-if="!nameEdit" class="d-flex aic">
                        <FluentEmoji v-if="activePage?.icon" :emojiName="activePage.icon" />
                        {{ activePage ? activePage.title : magnet.name }}
                    </h1>
                    <v-icon class="ml-4" v-if="!nameEdit && !activePage"
                        @click="nameEdit = true">mdi-pencil-outline</v-icon>
                    <v-text-field v-if="nameEdit" v-model="magnet.name" append-icon="mdi-check"
                        @click:append="nameEdit = false, save('name', magnet.name)"
                        @keyup.enter="nameEdit = false, save('name', magnet.name)" />
                </div>
            </div>

            <div class="body">
                <div class="pages" v-if="!activePage">
                    <div v-for="page in pages">
                        <div v-if="page.title !== 'Question'" class="page d-flex aic mt-4" @click="changePage(page)">
                            <FluentEmoji class="mr-4" :emojiName="page.icon" />
                            <h3>
                                {{ page.title }}
                            </h3>
                        </div>
                        <div class="page d-flex aic mt-4" v-for="(question, i) in page.questions"
                            @click="changePage(page, question)" v-else-if="page.questions">
                            <div class="d-flex jcsb w100 aic">
                                <div class="d-flex aic question-emoji">
                                    <FluentEmoji class="mr-4" emojiName="red-question-mark" />
                                    <h3 class="question">
                                        {{ question.question }}
                                    </h3>
                                </div>
                                <v-icon @click.stop="deleteInTable('questions', question.id)">
                                    mdi-trash-can-outline
                                </v-icon>
                            </div>
                        </div>
                        <div class="page border-dot d-flex aic mt-4" v-if="page.title === 'Question'"
                            style="opacity: 0.8" @click="addQuestion()">
                            <FluentEmoji class="mr-4" emojiName="plus" />
                            <h3>
                                Add question
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="page">
                        <div>
                            <v-tabs v-model="activePage.tab" v-if="activePage?.form[0].tab">
                                <v-tab v-for="tab in activePage.form" :key="tab.tab">
                                    {{ tab.tab }}
                                </v-tab>
                            </v-tabs>

                            <div class="form">
                                <div class="input" v-for="input in form?.fields">
                                    <div v-if="input?.is?.() != false">
                                        <Input v-if="activePage.title !== 'Question'" @save="inputChange()"
                                            :input="input" :object="magnet" :value="magnet[input.model]" />

                                        <Input v-else-if="activePage.title === 'Question'" :input="input"
                                            :object="question" :value="question[input.model]" @save="inputChange()"
                                            table="questions" />
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="btn-save-container">
                        <v-btn block elevation="0" rounded="xl" color="primary" @click="returnBack()">
                            Save changes
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="right d-flex aic jcc fdc h100">
            <TestimonialForm v-if="!loadTestimonial && magnet" :magnet="magnet" :edit="true" :goStep="goStep" />
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import FluentEmoji from '@/components/utils/FluentEmoji.vue';
import Input from '@/components/inputs/Input.vue';
import TestimonialForm from '../testimonialsInputs/TestimonialForm.vue';

export default {
    name: "Magnet",

    components: {
        FluentEmoji,
        Input,
        TestimonialForm
    },

    computed: {
        activePage() {
            return this.pages.find(page => page.is);
        },
        form() {
            if (this.activePage.form[0].tab) {
                return this.activePage.form[this.activePage.tab];
            } else {
                return this.activePage.form[0];
            }
        },
        question() {
            return this.activePage?.questions?.find(q => q.is)
        }
    },

    data() {
        return {
            goStep: null,
            loadTestimonial: false,
            nameEdit: false,
            magnet: null,
            loading: true,
            pages: [
                {
                    title: 'Magnet options',
                    icon: 'gear',
                    url: 'options',
                    step: '1Welcome',
                    form: [
                        {
                            tab: 'Design',
                            fields: [
                                {
                                    name: 'Brand logo',
                                    type: 'image',
                                    model: 'logo'
                                },
                                {
                                    name: 'Questions background',
                                    type: 'wallpaper',
                                    model: 'background_question'
                                },
                                {
                                    name: 'Primary color',
                                    type: 'color',
                                    model: 'color'
                                },
                                {
                                    name: 'Font',
                                    type: 'dropdown',
                                    model: 'font',
                                    options: [
                                        'DM sans',
                                        'Times New Roman', // Serif
                                        'Georgia',         // Serif
                                        'Garamond',        // Serif
                                        'Palatino',        // Serif
                                        'Bookman',         // Serif
                                        'Arial',           // Sans-Serif
                                        'Helvetica',       // Sans-Serif
                                        'Verdana',         // Sans-Serif
                                        'Tahoma',          // Sans-Serif
                                        'Trebuchet MS',    // Sans-Serif
                                        'Geneva',          // Sans-Serif
                                        'Courier New',     // Monospace
                                        'Lucida Console',  // Monospace
                                        'Monaco',          // Monospace
                                        'Comic Sans MS',   // Cursive
                                        'Brush Script MT', // Cursive
                                        'Impact',          // Fantasy
                                        'Charcoal'         // Fantasy
                                    ]
                                },
                                {
                                    name: 'Show progress bar',
                                    type: 'toggle',
                                    model: 'progress_bar'
                                },
                                {
                                    name: 'Show skooad branding',
                                    type: 'toggle',
                                    model: 'skooad_branding',
                                    pro: true
                                }, {
                                    name: 'Wallpaper',
                                    type: 'image',
                                    model: 'wallpaper'
                                },
                            ]
                        },
                        {
                            tab: 'Options',
                            fields: [
                                {
                                    name: 'Allow text answers',
                                    type: 'toggle',
                                    model: 'text_answers'
                                },
                                // {
                                //     name: 'Language',
                                //     type: 'dropdown',
                                //     model: 'language',
                                //     options: [
                                //         'English',
                                //         'French'
                                //     ]
                                // },
                                {
                                    name: 'Activate auto transcript',
                                    type: 'toggle',
                                    model: 'auto_transcript'
                                },
                                {
                                    name: 'Activate auto-translate',
                                    type: 'toggle',
                                    model: 'auto_translate',
                                    pro: true
                                },
                                {
                                    name: 'Activate consent',
                                    type: 'toggle',
                                    model: 'consent'
                                },
                                {
                                    name: 'Make consent mandatory',
                                    type: 'toggle',
                                    model: 'consent_mandatory'
                                },
                                {
                                    name: 'Consent text',
                                    type: 'text',
                                    model: 'consent_text'
                                },
                                {
                                    name: 'Notify me with new answers',
                                    type: 'toggle',
                                    model: 'notify_answers'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Welcome screen',
                    icon: 'waving-hand',
                    url: 'welcome',
                    step: '1Welcome',
                    form: [
                        {
                            fields: [
                                // {
                                //     name: 'Activate welcome page',
                                //     type: 'toggle',
                                //     model: 'welcome_page'
                                // },
                                {
                                    name: 'Title',
                                    type: 'text',
                                    model: 'welcome_title'
                                },
                                {
                                    name: 'Introductory message',
                                    type: 'textarea',
                                    model: 'welcome_message'
                                },
                                {
                                    name: 'Welcome video',
                                    type: 'video',
                                    model: 'welcome_video',
                                    pro: true
                                },
                                {
                                    name: 'Edit labels 1',
                                    type: 'text',
                                    model: 'welcome_label_1'
                                },
                                {
                                    name: 'Edit labels 2',
                                    type: 'text',
                                    model: 'welcome_label_2'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Question',
                    icon: 'pushpin',
                    url: 'questions',
                    step: '2Record',
                    questions: [],
                    form: [
                        {
                            fields: [
                                {
                                    name: 'Question',
                                    type: 'text',
                                    model: 'question'
                                },
                                {
                                    name: 'Details & tips',
                                    type: 'textarea',
                                    model: 'detail'
                                },
                                // {
                                //     name: 'Make mandatory',
                                //     type: 'toggle',
                                //     model: 'mandatory'
                                // },
                                {
                                    name: 'Max video answer duration',
                                    type: 'slider',
                                    model: 'max_duration',
                                    min: '5',
                                    max: '60',
                                    pro: true,
                                    unit: 'seconds'
                                },
                                {
                                    is: () => this.magnet.text_answers,
                                    name: 'Max text answer length',
                                    type: 'slider',
                                    model: 'max_text',
                                    min: '50',
                                    max: '500',
                                    unit: 'characters',
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Contact screen',
                    icon: 'telephone',
                    url: 'contact',
                    step: '4Contact',
                    form: [
                        {

                            fields: [
                                {
                                    name: 'Title',
                                    type: 'text',
                                    model: 'contact_title'
                                },
                                {
                                    name: 'Review notation',
                                    type: 'toggle',
                                    model: 'contact_note',
                                    askRequired: 'contact_note_required'
                                }, {
                                    name: 'Name',
                                    type: 'toggle',
                                    model: 'contact_name'
                                },
                                {
                                    name: 'Email',
                                    type: 'toggle',
                                    model: 'contact_email'
                                }, {
                                    name: 'Contact photo',
                                    type: 'toggle',
                                    model: 'contact_photo',
                                    askRequired: 'contact_photo_required'
                                },
                                {
                                    name: 'Job title',
                                    type: 'toggle',
                                    model: 'contact_job_title',
                                    askRequired: 'contact_job_title_required'
                                },

                                {
                                    name: 'Website',
                                    type: 'toggle',
                                    model: 'contact_website',
                                    askRequired: 'contact_website_required'
                                },
                                {
                                    name: 'Company name',
                                    type: 'toggle',
                                    model: 'contact_company_name',
                                    askRequired: 'contact_company_name_required'
                                },
                                {
                                    name: 'Company logo',
                                    type: 'toggle',
                                    model: 'contact_company_logo',
                                    askRequired: 'contact_company_logo_required'
                                },


                            ]
                        }
                    ]
                },
                {
                    title: 'Thank you screen',
                    icon: 'party-popper',
                    url: 'thank-you',
                    step: '5Thanks',
                    form: [
                        {
                            fields: [
                                {
                                    name: 'Title',
                                    type: 'text',
                                    model: 'thank_you_title'
                                },
                                {
                                    name: 'Thank you message',
                                    type: 'textarea',
                                    model: 'thank_you_message'
                                },
                                {
                                    name: 'Thank you video',
                                    type: 'video',
                                    model: 'thank_you_video',
                                    pro: true
                                },

                                {
                                    name: 'Call to action',
                                    type: 'toggle',
                                    model: 'thank_you_call_to_action'
                                },
                                {
                                    name: 'Call to action text',
                                    type: 'text',
                                    model: 'thank_you_call_to_action_text'
                                },
                                {
                                    name: 'Call to action link',
                                    type: 'text',
                                    model: 'thank_you_call_to_action_link'
                                },
                                {
                                    name: 'Share the answer',
                                    type: 'toggle',
                                    model: 'thank_you_share_answer'
                                },
                                {
                                    name: 'Send email to contact',
                                    type: 'toggle',
                                    model: 'thank_you_send_email',
                                    pro: true
                                },
                                {
                                    name: 'Email title',
                                    type: 'text',
                                    model: 'thank_you_email_title'
                                },
                                {
                                    name: 'Email message',
                                    type: 'textarea',
                                    model: 'thank_you_email_message'
                                },
                                {
                                    name: 'Socials links',
                                    type: 'multiples-toggle',
                                    model: 'thank_you_socials',

                                    keys: ['facebook', 'twitter', 'linkedin', 'whatsapp']
                                },
                            ]
                        }
                    ]
                }
            ]
        };

    },

    async created() {
        await this.loadMagnet(this.$route.params.id);
        if (this.$route.query.page) {
            this.pages.forEach(p => {
                if (p.url === this.$route.query.page) {
                    p.is = true;
                }
            })
            let getQuestionFromUrl = this.pages.find(p => p.title === 'Question').questions.find(q => q.id === this.$route.query.question)
            this.changePage(this.pages.find(p => p.url === this.$route.query.page), getQuestionFromUrl)
        }

    },

    methods: {
        inputChange() {
            this.loadTestimonial = true
            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        changePage(page, question) {
            this.loadTestimonial = true

            this.goStep = {
                component: page.step,
                questionid: page.title == 'Question' ? question.id : null
            }

            this.pages.forEach(p => p.is = false);
            page.is = true;

            this.pages.find(p => p.title === 'Question').questions.forEach(q => q.is = false);
            if (question) question.is = true;

            this.$router.push({
                query: {
                    page: page.url,
                    question: question ? question.id : null
                }
            });

            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        welcomePage() {
            let page = this.pages.find(p => p.title === 'Welcome screen')
            this.loadTestimonial = true

            this.goStep = {
                component: '1Welcome',
                questionid: null
            }

            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        returnBack() {
            if (this.activePage) {
                this.pages.find(page => page.is).is = false
                this.pages.find(p => p.title === 'Question').questions.forEach(q => q.is = false);
                this.welcomePage()
            }
            else {
                let index = this.$r.magnets.findIndex(m => m.id === this.magnet.id)
                this.$r.magnets[index] = this.magnet
                this.$router.push({ name: 'dashboard', params: { view: 'magnets' } })
            }
        },
        async loadMagnet(id) {
            this.loading = true;
            const response = await supabase.from('magnets').select(`*, questions(*)`).eq('id', id).limit(1)
            this.pages.find(page => page.title === 'Question').questions = response.data[0].questions;
            this.magnet = response.data[0];
            this.magnet.active = true;
            this.loading = false;
            return
        },
        actionHeader() {
            this.$router.push({ name: 'dashboard', params: { view: 'magnets' } });
        },
        async save(field, value) {
            const response = await supabase.from('magnets').update({ [field]: value }).eq('id', this.$route.params.id)
        },
        async deleteInTable(table, id) {
            const response = await supabase.from(table).delete().eq('id', id)
            if (table === 'questions') this.loadMagnet(this.$route.params.id)
        },
        async addQuestion() {
            const response = await supabase.from('questions').insert([
                {
                    magnet: this.$route.params.id
                }
            ]).select()
            await this.loadMagnet(this.$route.params.id)
 

            this.changePage(this.pages.find(p => p.title === 'Question'), this.pages.find(p => p.title === 'Question').questions.find(q => q.id === response.data[0].id))
        },
    }
}
</script>

<style scoped lang="scss">
.magnet {
    .left {
        min-width: 400px;
        padding: 20px;
        width: 30%;
        background-color: #ffffff;
    }

    .right {
        width: 70%;

        .preview {
            min-width: 300px;
            min-height: 300px;
            width: calc(50vw - 500px);
            height: calc(50vw - 500px);
            border: 1px solid #c2c2c2;
            border-radius: 10px;

        }
    }

    .pages {
        max-height: calc(100vh - 250px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5px;


        .page {
            cursor: pointer;
            border-radius: 50px;
            border: 1px solid #c2c2c2;
            padding: 15px 20px;

            &:hover {
                transition: 0.3s;
                transform: scale(1.01);
                background-color: #f5f5f5;
            }

            &.border-dot {
                border: 1px dashed #c2c2c2;
            }

            .question-emoji {
                max-width: calc(100% - 50px);

                .question {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .page {
        max-height: calc(100vh - 250px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 20px;
        padding: 10px;
    }

    .btn-save-container {
        border-top: 1px solid #efeff1;
        padding: 20px 0;
    }
}
</style>