<template>
    <div class="form-user inscription d-flex aic ">
        <div class="left-container">
            <div class="left">
                <h1 class="text-center mb-6">
                    Tell us more about you
                </h1>
                <Input v-for="input in inputs" :key="input.model" :input="input" :object="user"
                    :value="user[input.model]" />

                <v-btn @click="register" class="mt-6" color="primary" dark block>
                    Submit
                </v-btn>
            </div>
        </div>
        <div class="right" :style="{ backgroundImage: `url(${require(`@/assets/registerIllustration.png`)})` }" />
    </div>
</template>

<script>
import { supabase } from '../../supabase';
import Input from '@/components/inputs/Input.vue';

export default {
    name: 'register-form',
    components: {
        Input
    },
    data() {
        return {
            user: {
                firstname: '',
                lastname: '',
                phone: ''
            },
            inputs: [
                {
                    model: 'firstname',
                    name: 'First Name',
                    placeholder: 'Prénom',
                    type: 'text',
                    update: false
                },
                {
                    model: 'lastname',
                    name: 'Last Name',
                    placeholder: 'Nom',
                    type: 'text',
                    update: false
                },
                {
                    model: 'phone',
                    name: 'Phone',
                    placeholder: 'Téléphone',
                    type: 'text',
                    update: false
                }
            ]
        };
    },
    created() {

    },
    methods: {
        async register() {
            //test if inputs are not empty  and if phone is a phone number
            let regex = new RegExp(/^[0-9]{0,14}$/);
            if (this.user.firstname && this.user.lastname && regex.test(this.user.phone)) { 
                let { data, error } = await supabase.from('users').update({
                    firstname: this.user.firstname,
                    lastname: this.user.lastname,
                    phone: this.user.phone,
                    registered: true
                }).eq('id', this.$r.user.informations.id);
                if (error) {
                    console.log(error);
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            } else {
                this.$r.setMessage('error', 'Please fill all the fields correctly');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile {
    .left-container {
        width: 100%;
    }

    .right {
        display: none;
    }
}

.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;

    .left {
        max-width: 700px;
        padding: 0 2rem;
    }
}

.right {
    width: 40%;
    height: 100vh;
    background-size: cover;
}
</style>
