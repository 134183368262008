<template>
    <div class="footer">
        <p>
            <a href="https://skooad.fr/" class="mr-4"> © 2024 Skooad. All rights reserved.</a>

            <router-link to="/privacy-policy" class="mr-4">Privacy Policy</router-link>

            <router-link to="/mentions-legales" class="mr-4">Mentions légales</router-link>

            <router-link to="/cgv">Conditions générales de vente</router-link>
        </p>
    </div>
</template>

<script>
export default {
    name: 'footer',
    data () {
        return {
            drawer: false
        }
    },
}
</script>

<style scoped lang="scss">
.footer {
    font-size: 0.8rem;
    background-color: white;
    color: #fff;
    padding: 10px;
    text-align: center; 
}
</style>
