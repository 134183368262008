<template>
    <div class="cgv">
        <h1>Conditions Générales de Vente (CGV)</h1>

        <section>
            <h2>Article 1 : Objet</h2>
            <p>
                Les présentes conditions générales de vente (CGV) régissent les relations contractuelles entre l'entreprise
                <strong>Sage Nicolas</strong>, dont le siège social est situé à 4 rue Jean Macé, Grenoble, immatriculée sous le numéro
                SIRET 95224311100012, et toute personne souhaitant effectuer un achat via le site internet
                <a href="https://skooad.fr/">https://skooad.fr/</a> (ci-après dénommée "le client").
            </p>
        </section>

        <section>
            <h2>Article 2 : Services proposés</h2>
            <p>
                Le site <a href="https://skooad.fr/">https://skooad.fr/</a> propose un service mensuel au prix de 5 euros TTC. La nature
                du service est l'envoi d'un message personnalisé par email à une personne de votre choix après l'apparition de votre profil sur https://deces.matchid.io/search .
            </p>
        </section>

        <section>
            <h2>Article 3 : Tarifs</h2>
            <p>
                Le prix du service est fixé à 5 euros TTC mensuel. Sage Nicolas se réserve le droit de modifier ses prix à tout moment, étant entendu
                que le prix appliqué au client sera celui en vigueur au moment de la validation de la commande.
            </p>
        </section>

        <section>
            <h2>Article 4 : Commande</h2>
            <p>
                Le client passe commande sur le site <a href="https://skooad.fr/">https://skooad.fr/</a>. Pour acheter le service, le client
                doit suivre le processus de commande en ligne et fournir les informations nécessaires à la validation de la commande. La
                validation de la commande implique l'acceptation intégrale des présentes CGV.
            </p>
        </section>

        <section>
            <h2>Article 5 : Paiement</h2>
            <p>
                Le paiement est exigible immédiatement à la commande. Le client peut effectuer le paiement par carte bancaire, PayPal. Sage Nicolas utilise une plateforme de paiement sécurisée pour garantir la confidentialité et la sécurité des transactions : Stripe.
            </p>
        </section>

        <section>
            <h2>Article 6 : Livraison du service</h2>
            <p>
                Le service sera fourni au client dès réception de la confirmation de paiement. La livraison du service s'effectuera par un écran de confirmation de paiement.
            </p>
        </section>

        <section>
            <h2>Article 7 : Droit de rétractation</h2>
            <p>
                Conformément aux dispositions de l'article L221-18 du Code de la consommation, le client dispose d'un délai de 14 jours pour exercer
                son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités. Pour exercer ce droit, le client doit notifier
                sa décision de rétractation par mail à skooad.contact@gmail.com. Le remboursement
                sera effectué dans un délai de 14 jours suivant la réception de la demande de rétractation.
            </p>
        </section>

        <section>
            <h2>Article 8 : Responsabilité</h2>
            <p>
                Sage Nicolas ne saurait être tenue responsable des dommages directs ou indirects résultant de l'utilisation ou de la mauvaise
                utilisation du service acheté. La responsabilité de Sage Nicolas est limitée au montant de la commande.
            </p>
        </section>

        <section>
            <h2>Article 9 : Protection des données personnelles</h2>
            <p>
                Les informations collectées lors de la commande sont nécessaires pour la gestion de la transaction et seront utilisées conformément
                à notre politique de confidentialité. Conformément au RGPD, le client dispose d'un droit d'accès, de rectification et de suppression
                des données le concernant. Pour exercer ces droits, le client peut contacter skooad.contact@gmail.com
            </p>
        </section>

        <section>
            <h2>Article 10 : Litiges</h2>
            <p>
                Les présentes CGV sont soumises à la loi française. En cas de litige, les parties s'efforceront de trouver une solution amiable.
                À défaut, le litige sera porté devant les tribunaux compétents de Grenoble.
            </p>
        </section>

        <section>
            <h2>Article 11 : Acceptation des CGV</h2>
            <p>
                La validation de la commande implique l'acceptation sans réserve des présentes conditions générales de vente par le client.
            </p>
        </section>

        <section>
            <p>
                Pour toute question ou information complémentaire, veuillez contacter notre service client à skooad.contact@gmail.com
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Cgv'
}
</script>

<style scoped>
.cgv {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    overflow: auto;
    margin-top: 70px;
    max-height: calc(100vh - 140px);
    padding: 20px;
}

.cgv p {
    margin: 10px 0;
}

.cgv {
    text-decoration: none;
}

.cgv a:hover {
    text-decoration: underline;
}
</style>
<style lang="scss" scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {}
</style>