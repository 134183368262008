<template>
    <div class="d-flex w100">
        <div class="left-container">
            <div class="left d-flex fdc aic">
                <v-text-field type="password" v-model="password1" label="Password" variant="outlined" class="mt-4"
                    required></v-text-field>
                <v-text-field type="password" v-model="password2" label="Confirm password" variant="outlined"
                    class="mt-4" required></v-text-field>
                <v-btn @click="updatePassword" color="primary" elevation="0" class="mx-auto">
                    Confirm
                </v-btn>
            </div>
        </div>

        <div class="right" :style="{ backgroundImage: `url(${require(`@/assets/registerIllustration.png`)})` }" />
    </div>
</template>

<script>
import { supabase } from '@/supabase';
export default {
    name: 'login',
    data() {
        return {
            password1: '',
            password2: '',
        }
    },
    methods: {
        async updatePassword() {
            if (this.password1 !== this.password2) {
                this.$r.setMessage('error', 'Passwords do not match')
                return
            }

            const { data, error } = await supabase.auth.updateUser({
                password: this.password1,
            })
            if (error) {
                this.$r.setMessage('error', error.message)
                return
            }

            this.$r.setMessage('success', 'Password updated')
            this.$router.push('/')
        }

    }
}
</script>

<style lang="scss" scoped>
.left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;

    .left {
        width: calc(100% - 40px);

        :deep(.v-input) {
            width: 100%;
            max-width: 400px;
        }
    }
}

.right {
    width: 40%;
    height: 100vh;
    background-size: cover;
}
</style>
