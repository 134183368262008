<template>
    <div class="menu d-flex fdc jcsb">
        <div>
            <div class="create mb-10">
                <div class="v-btn">
                    <v-menu offset-y :close-on-content-click="true" :nudge-right="40" transition="scale-transition"
                        rounded>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" elevation="0" color="#ECE6F0" size="large" rounded="m">
                                <FluentEmoji class="mr-4" emojiName="pencil" />
                                Create
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="$r.newWidget(null, $router)">
                                <v-list-item-title>
                                    <div class="d-flex aic">
                                        <FluentEmoji class="mr-4" emojiName="magnet" />
                                        New magnet
                                    </div>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$r.newSpotlight(null, $router)">
                                <v-list-item-title>
                                    <div class="d-flex aic">
                                        <FluentEmoji class="mr-4" emojiName="star" />
                                        New spotlight
                                    </div>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$emit('updateView', 'stories'), $r.createStory.is = true">
                                <v-list-item-title>
                                    <div class="d-flex aic">
                                        <FluentEmoji class="mr-4" emojiName="purple-heart" />
                                        New story
                                    </div>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <div class="list">
                <div class="elm " v-for="link in linksMenu.filter(link => link.active || link.active == undefined)"
                    :key="link.name" @click="changeView(link)">
                    <v-btn class="w100" elevation="0" :color="view === link.view ? '#E4E7EC' : 'transparent'"
                        size="large" rounded="xl">
                        <FluentEmoji class="mr-4" :emojiName="link.emoji" />
                        {{ link.name }}
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div v-if="!$r.user?.informations?.pro">
                <div class="nb-stories st d-flex  jcsb mr-6 ml-6 mb-4">
                    <div>
                        Stories used
                    </div>
                    <div>
                        {{ storiesUsed }}/{{ $r.maxStoriesFree }}
                    </div>
                </div>
                <div class="increase-limit st ml-6 mb-4">
                </div>
                <v-btn @click="$r.openInNewTab($r.config.pricesURL)" rounded=" xl" elevation="0"
                    class="secondary mb-4 ml-4" size="small">
                    Increase limit
                </v-btn>
            </div>
            <div class="list">
                <div class="elm" v-for="link in linksMenuBottom.filter(link => link.active || link.active == undefined)"
                    :key="link.name" @click="view = link.view">
                    <v-btn @click="changeView(link)" class="w100" elevation="0"
                        :color="view === link.view ? '#E8DEF8' : 'transparent'" size="large" rounded="xl">
                        <FluentEmoji class="mr-4" :emojiName="link.emoji" :baw="true" />
                        {{ link.name }}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FluentEmoji from './utils/FluentEmoji.vue'
import { supabase } from '../supabase'

export default {
    name: 'Menu',

    components: {
        FluentEmoji
    },

    props: {
        view: {
            type: String,
            required: true
        },
        linksMenu: {
            type: Array,
            required: true
        },
        linksMenuBottom: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            storiesUsed: 0
        }
    },

    created() {
        this.getStoriesUsed()
    },

    methods: {
        async getStoriesUsed() {
            this.storiesUsed = this.$r.testisofspot.length
        },
        changeView(link) {
            //this.view = link.view
            this.$emit('updateView', link.view)
            this.$router.push({ name: 'dashboard', params: { view: link.view } });
        }
    }
}
</script>