<template>
    <div class="doughnut-chart">
        <svg :width="size" :height="size" viewBox="0 0 36 36">
            <path class="circle-bg" d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831" :stroke="backgroundColor" :stroke-width="thickness"
                fill="none" />
            <path class="circle" d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831" :stroke="color" :stroke-width="thickness" fill="none"
                stroke-linecap="round" :stroke-dasharray="progress + ', 100'" />
        </svg>
        <div class="text aic fdc">
            <p class="st">Completed</p>
            {{ progress }}%
        </div>
    </div>
</template>

<script>
export default {
    name: 'DoughnutChart',
    props: {
        progress: {
            type: Number,
            default: 0,
            validator: value => value >= 0 && value <= 100,
        },
        size: {
            type: Number,
            default: 165, // Size in pixels
        },
        thickness: {
            type: Number,
            default: 3, // Thickness of the doughnut ring
        },
        color: {
            type: String,
            default: '#4caf50', // Progress color
        },
        backgroundColor: {
            type: String,
            default: '#e0e0e0', // Background color of the doughnut
        },
        textColor: {
            type: String,
            default: '#000000', // Color of the progress text
        },
    }
};
</script>

<style scoped lang="scss">
.doughnut-chart {
    display: inline-block;
    position: relative;
}

.circle-bg {
    stroke: var(--background-color, #e0e0e0);
}

.circle {
    stroke: var(--color, #7e56da);
    transition: stroke-dasharray 0.6s ease;
}

.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: var(--text-color, #000000);
    font-weight: bold;

    .st {
        font-size: 0.8rem !important;
    }
}
</style>