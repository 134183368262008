import { createRouter, createWebHistory } from "vue-router";
import Register from "../views/User/Register.vue";
import PrivacyPolicy from "../views/conditions/PrivacyPolicy.vue";
import CGV from "../views/conditions/CGV.vue";
import MentionsLegales from "../views/conditions/MentionsLegales.vue";
import TestimonialForm from "../views/testimonialsInputs/TestimonialForm.vue";
import Review from "../views/testimonialsInputs/Review.vue";
import Dashboard from "@/views/Dashboard.vue";
import Magnet from "@/views/Magnet/Magnet.vue"
import ShowTestimonial from "@/views/Testimonial/ShowTestimonial.vue";
import Spotlight from "@/views/Spotlight/Spotlight.vue";
import Contact from "@/views/Contact/Contact.vue";
import PaymentForm from "@/components/PaymentForm.vue";
import PayementValidation from "@/components/PayementValidation.vue";
import ResetMDP from "@/views/User/ResetMDP.vue";
import UpdateMDP from "@/views/User/NewMDP.vue";
import RegisterForm from "@/views/User/RegisterForm.vue";
import Unsubscribe from "@/views/User/Unsubscribe.vue";
import Testing from "@/views/Testing.vue";

const routes = [
    // User routes
    {
        path: "/user/register",
        name: "register",
        component: Register,
        meta: { title: 'Register' }
    },
    {
        path: "/dashboard/:view?",
        name: "dashboard",
        component: Dashboard,
        meta: { title: 'Dashboard' }
    },
    {
        path: "/form/:id",
        name: "form",
        component: TestimonialForm,
        meta: { title: 'Testimonial Form' }
    },
    {
        path: "/testimonial/:id",
        name: "testimonial",
        component: ShowTestimonial,
        meta: { title: 'Testimonial' }
    },
    {
        path: "/magnet/:id",
        name: "magnet",
        component: Magnet,
        meta: { title: 'Magnet' }
    },
    {
        path: "/spotlight/:id",
        name: "spotlight",
        component: Spotlight,
        meta: { title: 'spotlight' }
    },
    {
        path: "/review/:id",
        name: "review",
        component: Review,
        meta: { title: 'Review' }
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' }
    },
    {
        path: "/widget",
        name: "widget",
        component: () => import("../widgets/App.vue"),
        meta: { title: 'Widget' }
    },
    {
        path: "/cgv",
        name: "cgv",
        component: CGV,
        meta: { title: 'CGV' }
    },
    {
        path: "/mentions-legales",
        name: "mentions-legales",
        component: MentionsLegales,
        meta: { title: 'Mentions Legales' }
    },
    {
        path: "/contact/:id",
        name: "contact",
        component: Contact,
        meta: { title: 'Contact' }
    },
    {
        path: "/payment-form",
        name: "payment-form",
        component: PaymentForm,
        meta: { title: 'Payment Form' }
    },
    {
        path: "/payment-validation",
        name: "payment-validation",
        component: PayementValidation,
        meta: { title: 'Payment Validation' }
    }, 
    {
        path: "/reset-password",
        name: "reset-password",
        component: ResetMDP,
        meta: { title: 'ResetMDP' }
    },
    {
        path: "/update-password",
        name: "update-password",
        component: UpdateMDP,
        meta: { title: 'UpdateMDP' }
    },
    {
        path: "/register-form",
        name: "register-form",
        component: RegisterForm,
        meta: { title: 'RegisterForm' }
    },
    {
        path: "/unsubscribe/:userid",
        name: "unsubscribe",
        component: Unsubscribe,
        meta: { title: 'Unsubscribe' }
    },
    {
        path: "/testing",
        name: "testing",
        component: Testing,
        meta: { title: 'Testing' }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/dashboard/home",
    }
];

const router = createRouter({
    history: createWebHistory(process.env.NODE_ENV === "production" ? "/" : "/"),
    routes,
});

export default router;
