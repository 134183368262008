<template>
    <div>
        <div class="stars d-flex ">
            <div v-for="i in 5" :style="`filter:brightness(${i > note ? 0.2 : 1})`"> 
                <FluentEmoji :emojiName="'star'" :size="18" style="margin: 0 2px !important;" />
                
            </div>
        </div>
    </div>
</template>

<script>
import FluentEmoji from './FluentEmoji.vue';
export default {
    props: {
        note: {
            type: Object,
            default: null,
        },
    },
    components: {
        FluentEmoji
    }
};
</script>

<style lang="scss" scoped>
.star {
    width: 15px !important;
    height: 15px !important;
    margin: 0 2px !important;
}
</style>