<template>
    <div v-if="$r.user.informations" class="fiche">
        <div class="header-infos-contact">
            <div class="infos ml-3 mt-6">
                <div v-for="info in infos" class="d-flex aic">
                    <!-- <v-icon class="mr-2" style="font-size: 1.4rem;">{{ info.icon }}</v-icon> -->
                    <Input :key="info.name" :input="info" :object="$r.user.informations"
                        :value="$r.user.informations[info.model]" />
                </div>
            </div>
        </div>
        <div v-if="$r.user.app_metadata.provider === 'email'">
            <v-btn v-if="!emailSent" @click="sendPasswordResetEmail" color="primary" elevation="0" class="mx-auto">
                Change password
            </v-btn>
            <v-alert v-else type="success" class="mt-4">
                An email has been sent to you with a link to reset your password.
            </v-alert>
        </div>
    </div>
</template>

<script>
import ImageStock from '@/components/utils/ImageStock.vue';
import Input from '@/components/inputs/Input.vue';
import { supabase } from '../../supabase';
export default {
    name: 'FicheInfos',
    components: {
        ImageStock,
        Input
    },
    data() {
        return {
            infos: [
                {
                    name: 'Email',
                    type: 'text',
                    model: 'email',
                    table: 'users',
                    style: 'margin-bottom: 20px;',
                    icon: 'mdi-email-outline',
                    editable: false
                },
                {
                    name: 'First name',
                    type: 'text',
                    model: 'firstname',
                    table: 'users',
                },
                {
                    name: 'Last name',
                    type: 'text',
                    model: 'lastname',
                    table: 'users',
                },
                {
                    name: 'Phone',
                    type: 'phone',
                    model: 'phone',
                    table: 'users',
                    icon: 'mdi-phone-outline'
                },
                {
                    name: 'Company',
                    type: 'text',
                    model: 'company',
                    table: 'users',
                    icon: 'mdi-domain'
                },
                {
                    name: 'Company logo',
                    type: 'image',
                    model: 'company_logo',
                    table: 'users',
                },
            ],
            emailSent: false
        }
    },
    methods: {
        async sendPasswordResetEmail() {
            const { data, error } = await supabase.auth.resetPasswordForEmail(
                this.$r.user.email, {
                redirectTo: `${this.$r.config.domain}update-password`,
            })
            this.emailSent = true
        }
    }
}

</script>

<style scoped lang="scss">
.fiche {
    max-width: 500px;
}
</style>

<!-- { "id": "303b766b-4080-426c-826f-de96524e8c83", "aud": "authenticated", "role": "authenticated", "email":
"nicolasage38@orange.fr", "email_confirmed_at": "2024-10-21T15:57:45.304659Z", "phone": "", "confirmed_at":
"2024-10-21T15:57:45.304659Z", "last_sign_in_at": "2024-10-21T15:58:29.591671109Z", "app_metadata": { "provider":
"email", "providers": [ "email" ] }, "user_metadata": { "email": "nicolasage38@orange.fr", "email_verified": false,
"phone_verified": false, "sub": "303b766b-4080-426c-826f-de96524e8c83" }, "identities": [ { "identity_id":
"8f71dae6-0d91-4a27-b038-d321aaf5ec91", "id": "303b766b-4080-426c-826f-de96524e8c83", "user_id":
"303b766b-4080-426c-826f-de96524e8c83", "identity_data": { "email": "nicolasage38@orange.fr", "email_verified": false,
"phone_verified": false, "sub": "303b766b-4080-426c-826f-de96524e8c83" }, "provider": "email", "last_sign_in_at":
"2024-10-21T15:57:45.295388Z", "created_at": "2024-10-21T15:57:45.295455Z", "updated_at": "2024-10-21T15:57:45.295455Z",
"email": "nicolasage38@orange.fr" } ], "created_at": "2024-10-21T15:57:45.265877Z", "updated_at":
"2024-10-21T15:58:29.593503Z", "is_anonymous": false, "informations": { "id": "303b766b-4080-426c-826f-de96524e8c83",
"created_at": "2024-10-21T15:57:45.265472+00:00", "email": "nicolasage38@orange.fr", "questionnary_employes": null,
"questionnary_discover": null, "questionnary_priority": null, "questionnary_answered": false, "quickstart_guide": true,
"subscription": 0, "pro": false, "stripe_id": null } } -->