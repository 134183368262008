<template>
    <div class="cookies-container">
        <div class="cookies" v-if="popupbas">
            <h1>Paramètres des cookies</h1>
            <br />
            <p>
                Nous utilisons des cookies et des technologies similaires pour aider à personnaliser le contenu et à
                offrir une meilleure expérience. Vous pouvez choisir de les personnaliser en cliquant sur le bouton
                préférences.
            </p>

            <div class="buttons">
                <v-btn
                    class="pref pa-2 mt-2 w100"
                    variant="outlined"
                    elevation="0"
                    color="primary"
                    @click="showPreferences = true, popupbas = false"
                    >Préférences</v-btn
                >
                <v-btn class="pa-2 mt-2 w100" color="#00c58e" elevation="0" @click="acceptCookies">Accepter</v-btn>
            </div>
        </div>
        <v-dialog v-model="showPreferences" max-width="600px">
            <v-card>
                <v-card-title>Préférences des cookies</v-card-title>
                <v-card-text>
                    <p>
                        Nous utilisons des cookies pour stocker des informations sur votre appareil et vous offrir une
                        meilleure expérience. Vous pouvez choisir de ne pas autoriser certains types de cookies en
                        cliquant sur les différentes catégories ci-dessous.
                    </p>
                    <v-checkbox v-model="preferences.analytics" label="Analytique" />
                    <v-checkbox v-model="preferences.marketing" label="Marketing" />
                    <v-checkbox v-model="preferences.essential" label="Essentiel" />
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="savePreferences">Sauvegarder</v-btn>
                    <v-btn color="primary" @click="showPreferences = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Cookies',

    data() {
        return {
            showPreferences: false,
            preferences: {
                analytics: true,
                marketing: true,
                essential: true
            },
            popupbas: true
        }
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
    color: black !important;
    padding: 10px;
    text-align: center;
    z-index: 9999;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: black !important;
    }
    .pref {
        :deep(.v-btn__content) {
            color: rgb(48, 79, 254) !important;
        }
    }
}
</style>