<template>
    <div v-if="contact" class="fiche">
        <div class="header-infos-contact">
            <div class="d-flex">
                <!-- <ImageStock :src="contact.photo" width="65px" height="65px" rounded /> -->
                <Input @save="$emit('updateContact')" :input="inputName" :object="contact" :value="contact.name"
                    labelled :style-label="{ 'font-size': '1.2rem', 'font-weight': 'bold' }" />
            </div>

            <div class="infos ml-3 mt-2">
                <Input @save="$emit('updateContact')" :input="photoInput" :object="contact" :value="contact.photo" />
                <div v-for="info in infos" class="d-flex aic mt-1">
                    <v-icon class="mr-2" style="font-size: 1.4rem;">{{ info.icon }}</v-icon>
                    <Input @save="$emit('updateContact')" :key="info.name" :input="info" :object="contact"
                        :value="contact[info.model]" labelled />
                </div>
                <Input @save="$emit('updateContact')" :input="companyInput" :object="contact"
                    :value="contact.company_logo" />
            </div>

            <v-btn class="button-secondary" elevation="0" rounded="xl" @click="deleteContact()">Delete contact</v-btn>
        </div>
    </div>
</template>

<script>
import ImageStock from '@/components/utils/ImageStock.vue';
import Input from '@/components/inputs/Input.vue';
import { supabase } from '../../supabase';
export default {
    name: 'FicheInfos',
    props: {
        contact: {
            type: Object,
            required: true
        }
    },
    components: {
        ImageStock,
        Input
    },
    data() {
        return {
            inputName:
            {
                name: 'Name',
                type: 'text',
                model: 'name',
                table: 'contacts',
                hideDetails: true
            },
            infos: [
                {
                    name: 'Email',
                    type: 'text',
                    model: 'email',
                    table: 'contacts',
                    hideDetails: true,
                    icon: 'mdi-email-outline'
                },
                //job title
                {
                    name: 'Job title',
                    type: 'text',
                    model: 'job_title',
                    table: 'contacts',
                    hideDetails: true,
                    icon: 'mdi-briefcase-outline'
                },
                //company
                {
                    name: 'Company',
                    type: 'text',
                    model: 'company_name',
                    table: 'contacts',
                    hideDetails: true,
                    icon: 'mdi-domain'
                },
                //website
                {
                    name: 'Website',
                    type: 'text',
                    model: 'website',
                    table: 'contacts',
                    hideDetails: true,
                    icon: 'mdi-web'
                }
            ],
            //contact photo
            photoInput: {
                name: 'Photo',
                type: 'image',
                model: 'photo',
                table: 'contacts',
                icon: 'mdi-image-outline'
            },

            companyInput: {
                name: 'Company logo',
                type: 'image',
                model: 'company_logo',
                table: 'contacts',
                icon: 'mdi-image-outline'
            }
        }
    },
    methods: {
        deleteContact() {
            this.$r.dialogConfirm = {
                is: true,
                title: 'Delete contact',
                message: 'Are you sure you want to delete this contact ? This action cannot be undone.',
                callback: () => this.deleteContactCB()
            }
        },
        async deleteContactCB() {
            let res = await supabase.from('contacts').delete().eq('id', this.contact.id)

            if (res) {
                this.$r.setMessage('success', 'Contact deleted successfully')
                this.$router.go(-1)
                this.$r.contacts = this.$r.contacts.filter(c => c.id !== this.contact.id)
                this.$r.stories = this.$r.stories.filter(s => s.contact !== this.contact.id)
            } else {
                this.$r.setMessage('error', 'An error occured while deleting the contact')
            }
        }
    }
}

</script>

<style scoped lang="scss">
.fiche {
    max-width: 500px;
}
</style>