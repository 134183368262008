<template>
    <div class="qr-code">
        <canvas ref="canvas"></canvas>
        <div class="download-container">
            <div class="download">
                <v-btn color="primary" rounded="xl" @click="downloadQRCode" elevation="0">
                    Download
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
    props: {
        url: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 150,
        }, 
        logoWidth: {
            type: Number,
            default: 30, // Width of the logo image
        },
        logoHeight: {
            type: Number,
            default: 30, // Height of the logo image
        },
    },
    mounted() {
        this.generateQRCode();
    },
    methods: {
        generateQRCode() {
            const canvas = this.$refs.canvas;

            QRCode.toCanvas(canvas, this.url, {
                width: this.width,
                color: {
                    // dark: '#7F56D9', // QR code color in hexadecimal
                    // light: '#FFFFFF' // Background color
                }
            }, (error) => {
                if (error) {
                    console.error(error);
                } else {
                    this.addLogoToQRCode(canvas);
                }
            });
        },
        addLogoToQRCode(canvas) { 
            // const context = canvas.getContext('2d');
            // const logo = new Image();
            // logo.src = `${this.$r.config.domain}/logo.png`;
            // logo.onload = () => {
            //     const x = (canvas.width - this.logoWidth) / 2;
            //     const y = (canvas.height - this.logoHeight) / 2;   
            //     context.drawImage(logo, x, y, this.logoWidth, this.logoHeight);
            // };
        },
        downloadQRCode() {
            const canvas = this.$refs.canvas;
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'qrcode.png';
            link.click();
        }
    }
};
</script>

<style lang="scss" scoped>
.qr-code {
    &:hover {
        cursor: pointer;

        .download-container {
            opacity: 1;
            transition: opacity 0.2s;
        }
    }

    canvas {
        border-radius: 10px;

    }

    .download-container {
        position: relative;
        height: 0;
        top: -100px;
        left: calc(50% - 54px);
        opacity: 0;

    }
}
</style>