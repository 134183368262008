<template>
    <div>
        <div v-for="(data, i) in datas.questions">
            <div v-if="i == step">
                <div class="prompt">
                    <h3> {{ data.question }}</h3>
                    <p>
                        {{ data.detail }}
                    </p>
                </div>
                <Webcam v-if="!edit" @setTranscript="addTranscript" @addRecord="nextStep($event, data)"
                    :maxTime="data.max_duration" />
                <div v-else>
                    <img style="width: 520px; border-radius: 20px" src="https://i.ibb.co/4ZCrW4N/Sans-titre-3.png"
                        alt="record" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Webcam from '../../../components/Webcam.vue'
import { supabase } from '../../../supabase'

export default {
    components: {
        Webcam
    },
    props: {
        datas: Object,
        goStep: {
            type: Object,
            default: null
        },
        testimonial: Object,
        edit: Boolean
    },
    data() {
        return {
            step: 0,
            transcription: ''
        };
    },
    created() {
        if (this.goStep) {
            this.step = this.datas.questions.findIndex(step => step.id == this.goStep.questionid)
        }
    },
    methods: {
        nextStep(e, data) {
            data.record = e
            data.filmed = true
            this.step++
            if (this.step == this.datas.questions.length) {
                this.mergeBlobs()
            }
        },
        async addTranscript(transcript) {
            if (!transcript) return
            let res = await supabase.from('testimonials').update({
                transcript: transcript
            }).eq('id', this.testimonial.id)

        },
        async mergeBlobs() {
            const formData = new FormData();

            this.datas.questions.forEach((prompt, index) => {
                formData.append('video', prompt.record, `video-${index}.webm`);
                formData.append(`prompt-${index}`, JSON.stringify(prompt));
            });
            let session = await supabase.auth?.getSession()
            //formData.append('Authorization', session.data.session.access_token);
            formData.append('uuid', this.testimonial.id);
            formData.append('magnet', JSON.stringify(this.datas));
            formData.append('Apikey', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55 - WYocsTyVYvxbGs')

            fetch(`${this.$r.config.service_video}video/upload`, {
                method: 'POST',
                body: formData,
            })
            this.$emit('nextStep')
        }
    }
};
</script>

<style scoped lang="scss">
.prompt {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 20px;
    }
}
</style>