<template>
    <div class="header d-flex aic jcsb  ">
        <div class="left d-flex aic" @click="$r.goto('/')" v-if="!$r.mobile">
            <img style="width: 150px" :src="require('@/assets/logo.png')" alt="Logo de skooad" class="logo"
                loading="lazy" />
            <h1 style="font-size: 1.5rem;display:none;" class="ml-2">skooad</h1>
        </div>
        <v-icon v-if="$r.mobile" @click="menuDashboard = !menuDashboard" size="30">mdi-menu</v-icon>

        <div class="right d-flex aic">
            <Menu class="menu-dashboard" :view="view" v-if="menuDashboard" @updateView="$emit('updateView', $event)"
                :linksMenu="linksMenu" :linksMenuBottom="linksMenuBottom" @click="menuDashboard = false" />


            <div class="d-flex aic">
                <v-btn v-if="$r.user?.informations?.pro" @click="$emit('updateView', 'settings')" rounded=" xl"
                    elevation="0" class="secondary mr-8" size="small">
                    <v-icon color="primary" class="mr-2" size="20">mdi-check-circle</v-icon>
                    Plan pro
                </v-btn>

                <v-btn v-else @click="$r.openInNewTab($r.config.pricesURL)" rounded=" xl" elevation="0"
                    class="secondary mr-8" size="small">
                    Upgrade
                    {{ $r.user?.informations?.pro ? 'PRO' : '' }}
                </v-btn>

                <FluentEmoji class="mr-6 clickable" emojiName="wrapped-gift" size="30" />
                <FluentEmoji class="mr-6 clickable" emojiName="ring-buoy" size="30"
                    @click="$emit('updateView', 'bug')" />

                <MenuHamburger :drawer="drawer" @setDrawer="drawer = $event" :linksMenu="linksMenu"
                    :linksMenuBottom="linksMenuBottom" :view="view" @updateView="$emit('updateView', $event)" />

            </div>
        </div>
    </div>
</template>

<script>
import FluentEmoji from '@/components/utils/FluentEmoji.vue'
import Menu from '@/components/Menu.vue'
import MenuHamburger from '@/components/MenuHamburger.vue'

export default {
    name: 'Header',
    data() {
        return {
            drawer: false,
            menuDashboard: false,
        }
    },
    props: {
        view: {
            type: String,
            required: true
        },
        linksMenu: {
            type: Array,
            required: true
        },
        linksMenuBottom: {
            type: Array,
            required: true
        }
    },

    components: {
        FluentEmoji,
        Menu,
        MenuHamburger
    },

    methods: {
        handleImageError(event) {
            event.target.src = require('@/assets/accountdefault.jpg')
        }
    }
}
</script>

<style scoped lang="scss">
.mobile {
    .logo {
        width: 50px !important;
    }
}

.header {
    z-index: 9;
    background-color: white;
    padding: 1rem;

    .logo {
        cursor: pointer;
    }

    .right {
        .v-btn {
            margin-left: auto;
        }

        .menu-dashboard {
            background-color: white;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 10;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
