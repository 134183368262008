<template>
    <div :class="{ 'selected': testiHover === testimonial }" :key="testimonial.id"
        @mouseenter="$emit('testiHover', testimonial)" @mouseleave="$emit('addView')" class="bubble">
        <div v-if="testimonial.type == 'video'">
            <div class="video-container" :style="{ width: bubbleSize(), height: bubbleSize() }">
                <img :src="`${$r.config.images}${testimonial.thumbnail}`" v-if="testimonial.thumbnail"
                    style="width: 100%; height: 100%; object-fit: cover;opacity: 1!important;" />
                <video class="h100" v-else :src="`${$r.config.videos}${testimonial.video}`" :controls="false" />
            </div>
        </div>
        <div v-else>
            <img v-if="testimonial.contacts.photo" class="img" :style="{ width: bubbleSize(), height: bubbleSize() }"
                :src="`${$r.config.images}${testimonial.contacts.photo}`" alt="testimonial" />
            <img v-else class="img" :style="{ width: bubbleSize(), height: bubbleSize() }"
                src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="testimonial" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        testimonial: Object,
        testiHover: Object,
        spotlight: Object,
        bubbleSize: Function
    }
};

</script>

<style scoped>
.img {
    opacity: 1 !important;
}
</style>