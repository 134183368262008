<template>
    <v-dialog v-model="dialog" max-width="400px">
        <div class="dialog-questionnary">
            <h1 class="mb-2">Welcome to Skooad !</h1>
            <p>
                Tell us a little about yourself so we can create a better experience for you.
            </p>
            <div class="form mt-4">
                <div v-for="(item, i) in form">
                    <div v-if="i == indexQ" class="d-flex fdc">
                        <p>{{ item.text }}</p>
                        <v-btn v-for="(btn) in item.items" @click="updateValue(item.model, btn.value)"
                            class="secondary rounded-xl mt-4" outlined>
                            {{ btn.text }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { supabase } from '../../supabase'
export default {
    name: 'dialogQuestions',
    data() {
        return {
            dialog: true,
            indexQ: 0,
            form: [
                {
                    text: 'How many employees does your company have?',
                    model: 'questionnary_employes',
                    items: [
                        {
                            text: 'Only me',
                            value: '1'
                        },
                        {
                            text: '2 to 10',
                            value: '2-10'
                        },
                        {
                            text: '11 to 49',
                            value: '11-49'
                        },
                        {
                            text: '50 to 249',
                            value: '50-249'
                        },
                        {
                            text: '250 and more',
                            value: '250+'
                        }
                    ]
                },
                {
                    text: 'What’s your top priority?',
                    model: 'questionnary_priority',
                    items: [
                        {
                            text: 'Testimonials & Reviews',
                            value: 'Testimonials & Reviews'
                        },
                        {
                            text: 'Feedback & Insights',
                            value: 'Feedback & Insights'
                        },
                        {
                            text: 'Talents & Team Members',
                            value: 'Talents & Team Members'
                        },
                        {
                            text: 'Branded Content',
                            value: 'Branded Content'
                        },
                        {
                            text: 'Other',
                            value: 'Other'
                        }
                    ]
                }, 
                {
                    text: 'One last question! How did you discover us?',
                    model: 'questionnary_discover',
                    items: [
                        {
                            text: 'Online search',
                            value: 'Online search'
                        },
                        {
                            text: 'Recommendation',
                            value: 'Recommendation'
                        },
                        {
                            text: 'Answering a Skooad form',
                            value: 'Answering a Skooad form'
                        },
                        {
                            text: 'Social media',
                            value: 'Social media'
                        },
                        {
                            text: 'Other',
                            value: 'Other'
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        async updateValue(model, value) { 
            if(this.indexQ < this.form.length - 1){
                this.indexQ++
            } else {
                await supabase.from('users').update({ questionnary_answered: true }).eq('id', this.$r.user.id)
                this.dialog = false
            }
            await supabase.from('users').update({ [model]: value }).eq('id', this.$r.user.id)

        }
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/_variables.scss';

.dialog-questionnary {
    padding: 20px;
    background-color: #FFFF;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 500;

    p {
        font-size: 1rem;
        font-weight: 400;
    }

    :deep(button.secondary),
    button.secondary {
        background-color: $secondary-color !important;
        color: #7F56D9 !important;
        font-weight: 800;
    }
}
</style>