<template>
    <div>
        <div v-if="!loading" class="d-flex aic jcc fdc w100">
            <div class="review-container">
                <!-- <video ref="recordedVideo" controls></video> -->
                <ImageStock width="500px" :src="testimonial.video" :testimonial="true" />

            </div>
            <div class="d-flex fdc jcse w100 mt-4">
                <v-btn @click="retake" color="primary" variant="outlined" elevation="0" class="mb-4">
                    Retake
                </v-btn>
                <v-btn @click="$emit('nextStep')" color="primary" elevation="0">
                    Submit
                </v-btn>
            </div>
        </div>
        <div class="d-flex aic jcc fdc w100 h100">
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </div>
    </div>
</template>

<script>
import ImageStock from '@/components/utils/ImageStock.vue';
import { supabase } from '../../../supabase';
export default {
    props: {
        datas: Object,
        testimonial: Object
    },
    async mounted() {
        if (!this.testimonial.video && this.datas.questions.map(q => q.record).filter(r => r).length) {
            this.mergeBlobs()
            this.waitVideo()
        } else if (!this.testimonial.video && !this.datas.questions.map(q => q.record).filter(r => r).length) {
            this.$router.push({ query: { ...this.$route.query, step: 2, reloaded: true } })
        }
    },
    data() {
        return {
            loading: false
        }
    },
    components: {
        ImageStock
    },
    methods: {
        waitVideo() {
            this.loading = true;
            this.$emit('loadTestimonial')
            setTimeout(() => {
                if (!this.testimonial.video) {
                    this.waitVideo()
                } else {
                    setTimeout(() => {
                        document.location.reload()
                    }, 2000);
                }
            }, 2000)
        },
        retake() {
            this.$router.push({ query: { ...this.$route.query, step: 2 } })
            setTimeout(() => {
                document.location.reload()
            }, 100)
        },
        async mergeBlobs() {
            this.loading = true;
            const formData = new FormData();

            this.datas.questions.forEach((prompt, index) => {
                formData.append('video', prompt.record, `video-${index}.webm`);
                formData.append(`prompt-${index}`, JSON.stringify(prompt));
            });
            let session = await supabase.auth?.getSession()
            //formData.append('Authorization', session.data.session.access_token);
            formData.append('uuid', this.testimonial.id);
            formData.append('magnet', JSON.stringify(this.datas));
            formData.append('Apikey', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImliYnRhbWRneXRnanNjbnR2enpjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzMzY1NTEsImV4cCI6MjAzNjkxMjU1MX0.QApkLafdFRU0mgGgbqZrNDcMRn55 - WYocsTyVYvxbGs')

            fetch(`${this.$r.config.service_video}video/upload`, {
                method: 'POST',
                body: formData,
            })
        }
    }
};
</script>

<style scoped lang="scss">
.review-container {
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile {
    .review-container {
        :deep(video) {
            width: calc(100vw - 100px) !important;
        }
    }
}

video {
    border-radius: 10px;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}
</style>
