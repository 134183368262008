<template>
    <div class="contact-container">
        <p>
            {{ datas.thank_you_message }}
        </p>

        <div class="d-flex jcc w100 mt-4 mb-4">
            <ImageStock width="400px" :src="datas.thank_you_video" />
        </div>

        <div v-if="datas.thank_you_call_to_action">
            <v-btn @click="$r.openInNewTab(datas.thank_you_call_to_action_link)" class="mt-4" type="submit"
                :color="datas.color || 'primary'" elevation="0" block>
                {{ datas.thank_you_call_to_action_text || 'Visit us' }}
            </v-btn>
        </div>

        <div class="share mt-4" v-if="datas.thank_you_socials?.length > 0">
            <h4 class="w100 d-flex jcc">
                Share
            </h4>
            <div class="d-flex jcc">
                <div v-for="(social, key) in datas.thank_you_socials" class="ma-2">
                    <v-btn rounded="xl" @click="shareOnSocial(key)" :color="datas.color || 'primary'" elevation="0"
                        icon>
                        <v-icon>mdi-{{ key }}</v-icon>
                    </v-btn>
                </div>
            </div>
            <!-- <div  class="share-url" @click="$r.copyToClipboard(`${$r.config.domain}testimonial/${testimonial?.id}`)">
                {{ `${$r.config.domain}uploads/${testimonial?.video}` }}
                <v-icon
                    @click="$r.copyToClipboard(`${$r.config.domain}testimonial/${testimonial?.id}`)">mdi-content-copy</v-icon>
                <v-icon
                    @click="$r.openInNewTab(`${$r.config.domain}testimonial/${testimonial?.id}`)">mdi-open-in-new</v-icon>
            </div> -->
        </div>
    </div>
</template>

<script>
import { supabase } from '../../../supabase';
import ImageStock from '@/components/utils/ImageStock.vue';
import FluentEmoji from '@/components/utils/FluentEmoji.vue';

export default {
    components: {
        ImageStock,
        FluentEmoji
    },
    props: {
        datas: Object,
        testimonial: Object
    },
    methods: {
        shareOnSocial(social) { 
            let url = `${this.$r.config.domain}testimonial/${this.testimonial?.id}`;
            let message = "Check out this testimonial video!";
            let encodedUrl = encodeURIComponent(url);
            let encodedMessage = encodeURIComponent(message);

            switch (social) {
                case "facebook":
                    this.$r.openInNewTab(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`);
                    break;
                case "twitter":
                    this.$r.openInNewTab(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedMessage}`);
                    break;
                case "linkedin":
                    this.$r.openInNewTab(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedMessage}`);
                    break;
                case "whatsapp":
                    this.$r.openInNewTab(`https://api.whatsapp.com/send?text=${encodedMessage} ${encodedUrl}`);
                    break;

                default:
                    break;
            }

        }
    }
};
</script>

<style scoped lang="scss">
.review-container {
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

video {
    border-radius: 10px;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}

.share-url {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 0.5rem;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
</style>
