<template>
    <div class="h100 mt-4">
        <div class="aic jcsb">
            <div style="width: 300px;">
                <v-text-field prepend-inner-icon="mdi-magnify" density="compact" variant="outlined" v-model="search"
                    @input="searchContacts" placeholder="Search contacts" hide-details clearable />
            </div>

            <v-btn color="primary" v-if="!multiSelect" @click="multiSelect = true">
                <v-icon class="mr-2">mdi-checkbox-multiple-marked-outline</v-icon>
                Multi Select
            </v-btn>

            <v-menu offset-y v-if="multiSelect && contactsSelected.length > 0">
                <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                    <v-list-item @click="$r.dialogConfirm = {
                        is: true,
                        title: 'Delete contacts',
                        message: 'Are you sure you want to delete these contacts ? This will delete all stories of all deleted contacts. This action cannot be undone.',
                        callback: () => this.deleteContacts()
                    }">
                        <v-list-item-title>
                            <v-icon class="mr-2">mdi-delete</v-icon>
                            Delete</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="multiSelect = false">
                        <v-list-item-title>
                            <v-icon class="mr-2">mdi-close</v-icon>
                            Cancel</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <div class="contacts w100" v-if="contacts.length">
            <div v-for="contact in contactF" class="d-flex aic w100">
                <v-checkbox color="primary" hide-details class="mr-4" v-if="multiSelect" v-model="contactsSelected"
                    :value="contact.id" />
                <div class="contact d-flex aic mt-2 jcsb pr-6 pt-2 pb-2 w100"
                    @click="$router.push({ name: 'contact', params: { id: contact.id } })">
                    <div class="d-flex aic">
                        <div class="photo ml-4" style="min-height: 55px;">
                            <ImageStock :src="contact.photo" width="50px" height="50px" rounded="true" />
                        </div>
                        <div class="infos d-flex fdc ml-4">
                            <div class="st">
                                {{ contact.name }}
                            </div>
                            <div class="small-text">
                                {{ contact.job_title }}
                            </div>
                            <div class="small-text">
                                {{ contact.company_name }}
                            </div>
                        </div>
                    </div>
                    <div class="right st primary-color tar">
                        <div>
                            {{ contact.testimonials.length }} {{ contact.testimonials.length > 1 ? 'stories' : 'story'
                            }}
                        </div>
                        <div>
                            {{ $r.timefrom(getLastTesttimonial(contact)?.created_at ||
                                contact.created_at) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="info h100 w100 d-flex aic jcc fdc" @click="$emit('updateView', 'magnets')">
            <div class="cp">
                No contacts yet? Share a magnet to start collecting stories from your contacts.
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import ImageStock from '@/components/utils/ImageStock.vue';
export default {
    name: "contacts",
    data() {
        return {
            contacts: [],
            contactF: [],
            contactsSelected: [],
            multiSelect: false,
        };
    },

    components: {
        ImageStock
    },

    async created() {
        if (!this.$r.contacts) {
            await this.fetchContacts();
        } else {
            this.contacts = this.$r.contacts;
            this.contactF = this.$r.contacts;
        }
    },

    methods: {
        getLastTesttimonial(contact) {
            //get last testimonial with created_at
            return contact.testimonials.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at)
            })[0]
        },
        async deleteContacts() {
            //ids : this.contactsSelected
            let { error } = await supabase
                .from('contacts')
                .delete()
                .in('id', this.contactsSelected);
            this.contacts = this.contacts.filter(contact => !this.contactsSelected.includes(contact.id))
            this.contactF = this.contactF.filter(contact => !this.contactsSelected.includes(contact.id))
        },
        async fetchContacts() {
            let { data, error } = await supabase
                .from('contacts')
                .select('*, testimonials(*)')
                .eq('user', this.$r.user.id)
                .order('created_at', { ascending: false });

            let contacts = data
            //sort contacts by last testimonial date or created_at
            contacts.sort((a, b) => {
                return new Date(this.getLastTesttimonial(b)?.created_at || b.created_at) - new Date(this.getLastTesttimonial(a)?.created_at || a.created_at)
            })
            if (error) {
                this.error = error.message;
            } else {
                this.contacts = contacts
                this.contactF = contacts
            }
        },
        searchContacts() {
            this.contactF = this.contacts.filter(contact => {
                return contact.name?.toLowerCase().includes(this.search.toLowerCase()) ||
                    contact.job_title?.toLowerCase().includes(this.search.toLowerCase()) ||
                    contact.company_name?.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style>

<style lang="scss" scoped>
.contact {
    border: 1px solid #e0e0e0;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
        transition: 0.3s;
        transform: scale(1.01);
        background-color: #f5f5f5;
    }
}
</style>
