<template>
    <div>
        <div v-if="!sended">
            <v-textarea class="mt-5" label="Tell us about the bug you found:" outlined rows="5"
                v-model="bug"></v-textarea>
            <v-btn @click="sended = true, $r.InsertValueInTable('bugsfeatures', { description: bug, type: view })"
                class="mt-5" color="primary">Send</v-btn>
        </div>
        <div v-else>
            <v-alert type="success" class="mt-5" outlined>
                <span>Thank you for your feedback!
                    <br>
                    We will work on it as soon as possible.
                </span>
            </v-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Bug',
    data() {
        return {
            bug: '',
            sended: false
        }
    },
    props: {
        view: String
    }
}
</script>