<template>
    <v-menu v-model="menuDashboard" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
        offset-y min-width="290px">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" color="primary" rounded="xl" size="small" elevation="0" icon="">
                <img @error="handleImageError" v-if="$r.user?.user_metadata?.picture"
                    :src="$r.user.user_metadata.picture" alt="Profile picture"
                    style="width: 38px; border-radius: 50%;" />
                <v-icon v-else size="25">
                    mdi-account-circle
                </v-icon>
            </v-btn>
        </template>
        <div class="menu-hamburger" @click="setDrawer(false)">
            <div class="top d-flex aic pa-4" @click="$emit('updateView', 'settings')">
                <div class="left mr-3">
                    <v-btn color="primary" rounded="xl" size="small" elevation="0" icon="">
                        <img @error="handleImageError" v-if="$r.user?.user_metadata?.picture"
                            :src="$r.user.user_metadata.picture" alt="Profile picture" class="profile-picture" />
                        <v-icon v-else size="25">
                            mdi-account-circle
                        </v-icon>
                    </v-btn>
                </div>
                <div class="right">
                    <div class="user-name">
                        {{ $r.user.informations.firstname }} {{ $r.user.informations.lastname }}
                    </div>
                    <div class="user-email">
                        {{ $r.user?.user_metadata?.email }}
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="menu"
                    @click="$router.push({ name: 'dashboard', params: { view: 'settings' } }), $emit('updateView', 'settings')">
                    Settings
                </div>
                <div v-if="$r.user" class="menu" @click="$r.logout()">
                    Log out
                </div>
            </div>
        </div>
    </v-menu>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
    name: "MenuHamburger",
    props: {
        drawer: Boolean,
        linksMenu: Array,
        linksMenuBottom: Array,
        view: String
    },
    data() {
        return {
            menuDashboard: false
        }
    },
    components: { Menu },
    methods: {
        setDrawer(value) {
            this.$emit('setDrawer', value)
        },
        handleImageError(event) {
            event.target.src = require('@/assets/accountdefault.jpg')
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-hamburger {
    z-index: 10;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.profile-picture {
    width: 38px;
    border-radius: 50%;
}

.user-name {
    font-weight: bold;
    font-size: 1rem;
}

.user-email {
    font-size: 0.8rem;
}

.bottom {
    border-top: 1px solid #f0f0f0;
}

.menu {
    padding: 0.8rem;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}
</style>
