<template>
    <div v-if="srcUrl">
        <img :style="imgStyle" :src="srcUrl" v-if="isImage" class="image" />
        <div :class="videoClass" style="position: relative; width: fit-content;" v-else-if="isVideo">
            <!-- image -->
            <img :style="imgStyle" :src="srcThumbnail" v-if="thumbnail && srcThumbnail != null && !played"
                class="ma-0 thumbnail" />
            <div v-else :style="imgStyle" />
            <!-- video -->
            <video ref="video" :muted="false" :autoplay="autoplay" loop @click="desactivePlay ? togglePlay : ''"
                :style="`width: ${width}; height: ${height};display:${(played || srcThumbnail == null) ? 'block' : 'none'}`">
                <source :src="srcUrl" type="video/mp4" @loadedmetadata="$emit('videoLoaded')" />
            </video>
            <!-- play button -->
            <div class="play-btn-container">
                <v-btn rounded="xl" @click="togglePlay" class="play-btn"
                    :style="`background: ${color + '99'}!important;`">
                    <v-icon>{{ isPlaying ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                </v-btn>
            </div>
            <!-- mute button -->
            <div class="mute-btn-container">
                <v-btn rounded="xl" @click="toggleMute" class="mute-btn">
                    <v-icon>{{ isMuted ? 'mdi-volume-off' : 'mdi-volume-high' }}</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Image',
    props: {
        src: String,
        thumbnail: { type: String, default: false },
        height: { type: String, default: '100%' },
        width: { type: String, default: '100%' },
        testimonial: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false },
        autoplay: { type: Boolean, default: false },
        desactivePlay: { type: Boolean, default: false },
        color: { type: String, default: '#d3a7ff' },
    },
    data() {
        return {
            images: ['png', 'jpg', 'jpeg', 'webp', 'gif', 'svg'],
            videos: ['mp4', 'webm', 'ogg'],
            isPlaying: false,
            played: false,
            autoplayWorks: false,
            isMuted: false
        };
    },
    async created() {
        if (this.autoplay) {
            this.isPlaying = true;
            setTimeout(() => {
                this.togglePlayFirst();
            }, 100);
        }
    },
    computed: {
        srcUrl() {
            if (this.src?.includes('http')) {
                return this.src;
            }
            const basePath = this.$r.config.videos;
            return this.testimonial ? `${basePath}${this.src}` : `${basePath}images/${this.src}`;
        },
        srcThumbnail() {
            if (this.srcThumbnail) {
                if (this.thumbnail?.includes('http')) {
                    return this.thumbnail;
                }
                const basePath = this.$r.config.images;
                return this.testimonial ? `${basePath}${this.thumbnail}` : `${basePath}images/${this.thumbnail}`;
            }
        },
        isImage() {
            return this.images.includes(this.srcUrl.split('.').pop());
        },
        isVideo() {
            return this.videos.includes(this.srcUrl.split('.').pop());
        },
        imgStyle() {
            return {
                height: this.height,
                width: this.width,
                borderRadius: this.rounded ? '100%' : this.radius || '10px'
            };
        },
        videoClass() {
            return {
                playing: this.isPlaying,
                paused: !this.isPlaying
            };
        }
    },
    methods: {
        togglePlayFirst() {
            const video = this.$refs.video;
            if (video) {
                if (this.isPlaying) {
                    try {
                        video.play().catch(() => {
                            this.isPlaying = false;
                        });
                    } catch (e) {

                    }
                } else video.pause();
            }
            this.isPlaying = !video?.paused;

            this.played = true
        },
        togglePlay() {
            const video = this.$refs.video;
            if (video) {
                video.paused ? video.play() : video.pause();
                this.isPlaying = !video.paused;
                this.played = true
            }
        },
        toggleMute() {
            const video = this.$refs.video;
            if (video) {
                video.muted = !video.muted;
            }
            this.isMuted = video.muted;
        },
    }
};
</script>

<style scoped lang="scss">
img,
video {
    border-radius: 10px;
    display: block;
    margin: 10px;
}

.image {
    object-fit: cover;
    border-radius: 100%;
    opacity: 1!important;
}

video {
    margin: 0;
    cursor: pointer !important;
}

.thumbnail {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    opacity: 1 !important;
}

.mute-btn-container {
    width: 0;

    .mute-btn {
        transform: scale(0.7) translateY(15px) translateX(-15px);
        position: absolute;
        bottom: 10px;
        left: 10px;
        background: #b9b9b981;
        color: white;
        border: none;
        border-radius: 50% !important;
        padding: 0 !important;
        width: 50px !important;
        height: 64px !important;

        i {
            font-size: 31px;
        }
    }
}

.play-btn-container {
    width: 0;

    .play-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        // background: #d3a7ff81;
        color: white;
        border: none;
        border-radius: 50% !important;
        padding: 0 !important;
        width: 50px !important;
        height: 64px !important;

        i {
            font-size: 31px;
        }
    }
}

.playing .play-btn {
    transition: all 0.3s;
    opacity: 0;
}

.playing .mute-btn {
    transition: all 0.3s;
    opacity: 0;
}

.playing:hover .play-btn {
    opacity: 1;
}

.playing:hover .mute-btn {
    opacity: 1;
}
</style>