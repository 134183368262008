<template>
    <div class="mentions-legales">
        <h1>Mentions Légales</h1>

        <section>
            <h2>Éditeur du site</h2>
            <p>
                Le site <a href="https://skooad.fr/">https://skooad.fr/</a> est édité par :<br>
                <strong>Sage Nicolas</strong><br>
                Adresse : 4 rue Jean Macé, Grenoble<br>
                SIRET : 95224311100012<br>
                Email : <a href="mailto:skooad.contact@gmail.com">skooad.contact@gmail.com</a>
            </p>
        </section>

        <section>
            <h2>Hébergement du site</h2>
            <p>
                Le site est hébergé par :<br>
                Hostinger<br>
                Adresse : 61 Lordou Vironos Street, Larnaca, 6023, Cyprus
            </p>
        </section>

        <section>
            <h2>Responsable de la publication</h2>
            <p>
                Directeur de la publication : <strong>Nicolas Sage</strong><br>
                Email : <a href="mailto:skooad.contact@gmail.com">skooad.contact@gmail.com</a>
            </p>
        </section>

        <section>
            <h2>Propriété intellectuelle</h2>
            <p>
                L'ensemble des contenus présents sur le site <a href="https://skooad.fr/">https://skooad.fr/</a> (textes, images, logos, vidéos, etc.) est protégé par les lois en vigueur en France concernant la propriété intellectuelle et le droit d'auteur.<br>
                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de <strong>Sage Nicolas</strong>.
            </p>
        </section>

        <section>
            <h2>Protection des données personnelles</h2>
            <p>
                Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification, de suppression et d'opposition au traitement des données personnelles vous concernant. Pour exercer ce droit, vous pouvez nous contacter à l'adresse suivante : <a href="mailto:skooad.contact@gmail.com">skooad.contact@gmail.com</a>.<br>
                Pour plus d'informations, consultez notre <a href="[Lien vers la politique de confidentialité]">politique de confidentialité</a>.
            </p>
        </section>

        <section>
            <h2>Cookies</h2>
            <p>
                Le site <a href="https://skooad.fr/">https://skooad.fr/</a> utilise des cookies pour améliorer votre expérience de navigation et à des fins de mesure d'audience.<br>
                En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies. Vous pouvez désactiver les cookies en suivant les instructions fournies par votre navigateur.
            </p>
        </section>

        <section>
            <h2>Limitation de responsabilité</h2>
            <p>
                <strong>Sage Nicolas</strong> ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <a href="https://skooad.fr/">https://skooad.fr/</a>.<br>
                <strong>Sage Nicolas</strong> décline toute responsabilité quant à l'utilisation qui pourrait être faite des informations et contenus présents sur ce site.
            </p>
        </section>

        <section>
            <h2>Litiges</h2>
            <p>
                Les présentes mentions légales sont régies par le droit français. En cas de litige, et après tentative de recherche d’une solution amiable, compétence est attribuée aux tribunaux compétents de Grenoble.
            </p>
        </section>

        <section>
            <h2>Contact</h2>
            <p>
                Pour toute demande d'information, vous pouvez nous contacter à l'adresse suivante : <a href="mailto:skooad.contact@gmail.com">skooad.contact@gmail.com</a>.
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: 'MentionsLegales'
}
</script>

<style scoped>
.mentions-legales {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    overflow: auto;
    margin-top: 70px;
    max-height: calc(100vh - 140px);
}

.mentions-legales p {
    margin: 10px 0;
}

.mentions-legales a {
    text-decoration: none;
}

.mentions-legales a:hover {
    text-decoration: underline;
}
</style>
<style lang="scss" scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {}
</style>