<template>
    <div>

    </div>
</template>

<script>
import { supabase } from '../../supabase';
export default {
    name: 'Unsubscribe',
    data() {
        return {
        }
    },
    async created() {
        let { data, error } = await supabase.from('users').update({
            mail_unsubscribed: true
        }).eq('id', this.$r.user.informations.id);
        this.$router.push('/');
        this.$r.setMessage('success', 'You have been unsubscribed from our mailing list'); 
    } 
}

</script>