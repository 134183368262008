<template>
    <div class="mt-6">
        <h3>
            Plan and billing
        </h3>
        <div class="mt-4">
            <v-btn v-if="$r.user?.informations?.pro" @click="manageSession()" rounded=" xl" elevation="0"
                class="button-primary mr-8" size="small">
                Manage subscription
            </v-btn>
            <v-btn v-else @click="$r.openInNewTab('https://app.skooad.com/user/register?gopro=true')" rounded=" xl"
                elevation="0" class="button-primary mr-8" size="small">
                Upgrade to PRO
            </v-btn>
        </div>
        <div class="about-you mt-6">
            <h3>About you</h3>
        </div>
        <Account :contact="$r.user?.informations" />
    </div>
</template>

<script>
import FluentEmoji from '@/components/utils/FluentEmoji.vue'
import Account from '../User/Account.vue';

export default {
    name: 'Settings',
    components: {
        FluentEmoji,
        Account
    },
    props: {
        view: {
            type: String,
            required: true
        },
        linksMenu: {
            type: Array,
            required: true
        },
        linksMenuBottom: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            storiesUsed: 0,
            stripe: null
        }
    },
    methods: {
        async manageSession() {


            try {
                const response = await fetch(`${this.$r.config.service_video}payements/create-portal-session`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ customerId: this.$r.user?.informations.stripe_id }),
                });

                const data = await response.json();
                if (data.url) {
                    window.location.href = data.url;  // Redirect to the Stripe Customer Portal
                }
            } catch (error) {
                console.error('Error redirecting to billing portal:', error);
            }
            // const session = await this.stripe.billingPortal.sessions.create({
            //     customer: this.$r.user?.informations.stripe_id, // Replace with the customer ID
            //     return_url: 'https://app.skooad.com/',
            // }); 
        }
    }
}
</script>