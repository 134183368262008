<template>
    <div :class="['message', $r.message.type, { active: $r.message.text }]">
        <div class="msg-inner">
            <v-icon color="white" large>{{ getIcon($r.message.type) }}</v-icon>
            <div class="msg-content">
                <span>{{ getMessage($r.message.text) }}</span>
                <small v-show="$r.message.detail">{{ getDetail($r.message.detail) }}</small>
            </div>
            <v-btn elevation="0" variant="text" color="white" @click="close()">
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "Message",

    data: () => ({
        timeout1: null,
        timeout2: null,
    }),

    updated() {
        console.log("updated")
        if (this.timeout1) clearTimeout(this.timeout1)
        if (this.$r.message.delay > 0) {
            console.log("\tstart timeout delay: ", this.$r.message.delay)
            this.timeout1 = setTimeout(() => {
                this.close()
            }, this.$r.message.delay * 1000)
        }
    },

    methods: {
        getMessage(text) {
            if (this.$r.message.text && typeof this.$r.message.text === "object") {
                this.$r.message.text = this.$r.message.text.message
            }

            if (this.$r.message.text?.length > 300) {
                return this.$r.message.text.slice(0, 300) + "..."
            }

            return text
        },

        getDetail(detail) {
            return detail?.replace("Error: ", "") || null
        },

        getIcon(type) {
            switch (type) {
                case "error":
                    return "mdi-alert-circle"
                case "success":
                    return "mdi-check-circle"
                case "warning":
                    return "mdi-alert"
                case "info":
                    return "mdi-information"
                default:
                    return "mdi-information"
            }
        },

        close() {
            console.log("call close()")
            if (this.timeout2) clearTimeout(this.timeout2)
            this.timeout2 = setTimeout(() => {
                this.$r.message.type = null
                this.$r.message.text = null
                this.$r.message.detail = null
                this.$r.message.delay = 15
                console.log("finish close()")
            }, 1000)

            console.log('remove "active"')
            document.querySelector(".message").classList.remove("active")
        },
    },
}
</script>

<style lang="scss" scoped>
.message {
    position: fixed;
    top: -15em;
    transform: translate(-50%, calc(100% + 2em));
    background-color: #424242;
    margin: 0 auto;
    border-radius: 4px !important;
    box-shadow: 0 1px 4px rgba(black, 0.5);
    overflow: hidden;
    transition: all 0.3s;
    z-index: 100000;
    max-width: 400px;
    max-height: 100px;
    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        background-color: rgba(white, 0.25);
    }
    &.error {
        background-color: #f44336;
    }
    &.success {
        background-color: #4caf50;
    }
    &.warning {
        background-color: #ff9800;
    }
    &.info {
        background-color: #2196f3;
    }

    &.active {
        transform: translateY(17em);
        left: 0;
        right: 0; 
    }

    .msg-inner {
        display: flex;
        align-items: center;
        padding: 1em 2em;
        font-weight: 300;

        .v-icon {
            margin-right: 1em;
        }

        strong {
            display: block;
            color: rgba(white, 0.5);
            font-size: 22px;
        }

        span {
            padding-right: 1em;
            font-size: 18px;
            color: white;
        }

        small {
            display: block;
            font-size: 14px;
            margin-top: 0.25em;
            color: rgba(white, 0.75);
        }

        .v-btn {
            margin-left: auto;

            .v-icon {
                margin-right: 0 !important;
            }
        }
    }
}
</style>
