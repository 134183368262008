<template>
    <div>
        <!-- <button @click="checkout">Checkout</button> -->
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'


export default {
    data() {
        return {
            stripe: null,
            items: [{ price: process.env.VUE_APP_PROUCT_ID, quantity: 1 }]
        }
    },
    async mounted() {
        if (this.$r.user.informations.pro) {
            this.$router.push({ name: 'dashboard' })
            this.$r.setMessage('info', 'Vous êtes déjà abonné')
        }
        else {
            setTimeout(async () => {
                this.stripe = await loadStripe(
                    process.env.VUE_APP_STRIPE_API
                )
                this.checkout()
            }, 100)
        }
    },
    methods: {
        checkout() {
            let successUrl = process.env.VUE_APP_BASE_URL + 'payment-validation?session_id={CHECKOUT_SESSION_ID}&ok=true'
            let cancelUrl = process.env.VUE_APP_BASE_URL + 'payment-validation?ok=false'
            window.addEventListener('beforeunload', this.beforeUnloadHandler)
            this.stripe.redirectToCheckout({
                lineItems: this.items,
                mode: 'subscription',
                successUrl: successUrl,
                cancelUrl: cancelUrl,
                customerEmail: this.$r.user.email
            })

        },
        beforeUnloadHandler(event) {
            event.preventDefault()
            event.returnValue = '' // Setting returnValue to a non-void value prompts the user with a confirmation dialog
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeUnloadHandler)
    }
}
</script>