<template>
    <div class="contact-container pb-6">
        <div v-for="inputForm in form">
            <div v-if="isInput(inputForm)" class="mb-2">
                <Input :color="datas.color" :table="'testimonials'" :input="inputForm" :object="testimonial"
                    :value="testimonial?.[inputForm.model]" />
                <div style="font-size: 12px; color: grey; margin-top: 5px;" v-if="inputForm.name == 'Email adress*'">
                    Your email will never be shared
                </div>
            </div>
        </div>
        <v-btn elevation="0" :color="datas.color || 'primary'" block class="mt-4" @click="updateOrInsertContact()">
            Submit
        </v-btn>
    </div>
</template>

<script>
import { supabase } from '../../../supabase';
import Input from '@/components/inputs/Input.vue';

export default {
    components: {
        Input
    },
    props: {
        datas: Object,
        testimonial: Object
    },
    created() {
        this.datas.id = this.$route.params.id
    },
    data() {
        return {
            form: [
                {
                    name: 'Rate us',
                    type: 'stars',
                    model: 'contact_note',
                },
                {
                    name: 'Your name',
                    type: 'text',
                    model: 'name',
                    update: false,
                    required: true
                },
                {
                    name: 'Email adress',
                    type: 'text',
                    model: 'email',
                    hideDetails: true,
                    update: false,
                    required: true
                },
                {
                    name: 'Your photo',
                    type: 'image',
                    model: 'photo',
                    table: 'contacts',
                    update: false
                },
                {
                    name: 'Job title',
                    type: 'text',
                    model: 'job_title',
                    update: false
                },
                {
                    name: 'Your website',
                    type: 'text',
                    model: 'website',
                    update: false
                },
                {
                    name: 'Company name',
                    type: 'text',
                    model: 'company_name',
                    update: false
                },
                {
                    name: 'Company logo',
                    type: 'image',
                    model: 'company_logo',
                    update: false
                }]
        }
    },
    created() {
        this.form.forEach(input => {
            let name
            if (input.model == 'contact_note') {
                name = 'contact_note_required'
            } else {
                name = `contact_${input.model}_required`
            }
            if (this.datas[name]) {
                input.required = true
            }
        })
        console.log(this.form);

    },
    methods: {
        isInput(input) {
            return this.datas[input.model] !== false
        },
        testRequireds() {
            let requireds = this.form.filter(input => input.required)
            let errors = []
            requireds.forEach(input => {
                if (!this.testimonial[input.model]) {
                    errors.push(input.name)
                }
            })
            return errors
        },
        async updateOrInsertContact() {
            let test = await this.testRequireds()
            if (test.length > 0) {
                this.$r.setMessage('error', `Please fill in the following fields: ${test.join(', ')}`)
                return
            }

            // Test if the contact already exists with email
            const { data, error } = await supabase
                .from('contacts')
                .select('*')
                .eq('email', this.testimonial.email)

            if (error) {
                console.error('error', error)
                return
            }

            const contactData = {
                name: this.testimonial.name,
                email: this.testimonial.email,
                photo: this.testimonial.photo,
                job_title: this.testimonial.job_title,
                website: this.testimonial.website,
                company_name: this.testimonial.company_name,
                company_logo: this.testimonial.company_logo,
                user: this.$r.user.id
            }

            let result
            if (data.length > 0) {
                // Update contact
                result = await supabase
                    .from('testimonials')
                    .update({ contact: data[0].id })
                    .eq('id', this.testimonial.id)
            } else {
                // Insert contact and select the id
                result = await supabase
                    .from('contacts')
                    .insert(contactData).select('id')

                this.testimonial.contact = result.data[0].id
                await supabase
                    .from('testimonials')
                    .update({ contact: result.data[0].id })
                    .eq('id', this.testimonial.id)
            }

            if (result.error) {
                console.error('error', result.error)
            } else { 
                this.$r.sendMail(this.datas.user, 'New story submitted', 'story-received', null, {
                    urlTestimonial: `https://app.skooad.com/testimonial/${this.testimonial.id}`, name: contactData?.name, unsubscribeLink: `https://app.skooad.com/unsubscribe/${this.datas.user}`
                })
                this.$emit('nextStep')
            }
        }
    }
};
</script>

<style scoped>
.review-container {
    min-width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


video {
    border-radius: 10px;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
}
</style>
