<template>
    <div class="spotlight" :class="!$r.mobile ? 'd-flex' : ''" v-if="spotlight">

        <v-dialog v-model="dialogType" persistent max-width="800px" content-class="dialog-type">
            <v-card>
                <v-card-title>
                    <h2>Choose a layout</h2>
                    <div class="st">
                        Floating or carousel? Select the layout that best fits your spotlight.
                    </div>
                </v-card-title>

                <div class="d-flex aic jcse pb-10 mt-4">
                    <div class="bloc"
                        @click="$r.updateValueInTable('spotlights', 'id', $route.params.id, 'type', 'floating'), dialogType = false">
                        <img src="https://i.ibb.co/SQ0hCqv/Capture-d-cran-2024-10-15-190020.png" alt="Floating" />
                        <div class="text st mt-4 mb-4">
                            Floating
                        </div>
                    </div>
                    <div class="bloc"
                        @click="$r.updateValueInTable('spotlights', 'id', $route.params.id, 'type', 'carousel'), dialogType = false">
                        <img src="https://i.ibb.co/bBYJ3WG/Capture-d-cran-2024-10-15-190120.png" alt="Floating" />
                        <div class="text st mt-4 mb-4">
                            Carousel
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <div class="left">
            <div class="head">
                <div class="back">
                    <v-icon class="mb-4" @click="returnBack()">
                        mdi-arrow-left
                    </v-icon>
                </div>

                <div class="title d-flex aic mb-6">
                    <h1 v-if="!nameEdit" class="d-flex aic">
                        <FluentEmoji v-if="activePage?.icon" :emojiName="activePage.icon" />
                        {{ activePage ? activePage.title : spotlight.name }}
                    </h1>
                    <v-icon class="ml-4" v-if="!nameEdit && !activePage"
                        @click="nameEdit = true">mdi-pencil-outline</v-icon>
                    <v-text-field v-if="nameEdit" v-model="spotlight.name" append-icon="mdi-check"
                        @click:append="nameEdit = false, save('name', spotlight.name)"
                        @keyup.enter="nameEdit = false, save('name', spotlight.name)" />
                </div>
            </div>

            <div class="body">
                <div class="pages" v-if="!activePage">
                    <div v-for="page in pages">
                        <div class="page d-flex aic mt-4" @click="changePage(page)">
                            <FluentEmoji class="mr-4" :emojiName="page.icon" />
                            <h3>
                                {{ page.title }}
                            </h3>
                        </div>

                    </div>
                </div>
                <div class="page" v-else>
                    <div>
                        <v-tabs v-model="activePage.tab" v-if="activePage?.form[0].tab">
                            <v-tab v-for="tab in activePage.form" :key="tab.tab" @click="reload">
                                {{ tab.tab }}
                            </v-tab>
                        </v-tabs>
                        <div class="form" :class="activePage.title">
                            <div class="input" v-for="input in form?.fields">
                                <Input @save="inputChange()" :input="input"
                                    v-if="input.type !== 'stories' && spotlight && !loading && input.is?.(spotlight) != false"
                                    :table="'spotlights'" :object="spotlight" :value="spotlight[input.model]" />
                                <div v-else-if="input.type === 'stories'">
                                    <h3 class="mb-4">
                                        Add some love to your spotlight
                                    </h3>
                                    <Stories @loadspotlight="loadspotlight" :fromSpotlight="true"
                                        @addTestimonial="addTestimonial" @removeTestimonial="removeTestimonial" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right" v-if="!loading">
            <div class="h100">
                <Widget class="mr-4 mt-4" v-if="spotlight && testimonials.length > 0" :spotlightP="spotlight"
                    :editing="true" :domain="spotlight?.domains?.[0]" />
                <div v-else-if="testimonials.length === 0" class="d-flex w100 h100 aic jcc st">
                    It’s looking empty! Add some stories to your spotlight.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import FluentEmoji from '@/components/utils/FluentEmoji.vue';
import Input from '@/components/inputs/Input.vue';
import TestimonialForm from '../testimonialsInputs/TestimonialForm.vue';
import Widget from '../../widgets/App.vue';
import { all } from 'axios';
import { load } from 'webfontloader';
import TestimonialCard from '@/components/TestimonialCard.vue';
import Stories from '../DashboardViews/stories.vue';

export default {
    name: "spotlight",

    components: {
        FluentEmoji,
        Input,
        TestimonialForm,
        Widget,
        TestimonialCard,
        Stories
    },

    computed: {
        activePage() {
            return this.pages.find(page => page.is);
        },
        form() {
            if (this.activePage.form[0].tab) {
                return this.activePage.form[this.activePage.tab];
            } else {
                return this.activePage.form[0];
            }
        }
    },

    data() {
        return {
            dialogType: false,
            goStep: null,
            loadTestimonial: false,
            nameEdit: false,
            spotlight: null,
            loading: true,
            valuesFilters: {
                contact_note: null
            },
            filters: [
                {
                    label: 'Rating',
                    model: 'contact_note',
                    options: [
                        { label: '1 star', value: 1 },
                        { label: '2 stars', value: 2 },
                        { label: '3 stars', value: 3 },
                        { label: '4 stars', value: 4 },
                        { label: '5 stars', value: 5 }
                    ]
                }
            ],
            pages: [{
                title: 'Design',
                icon: 'control-knobs',
                url: 'options',

                form: [
                    {
                        tab: 'Design',
                        fields: [
                            // {
                            //     name: 'Content size',
                            //     type: 'dropdown',
                            //     model: 'content_size',
                            //     options: [
                            //         'small',
                            //         'medium',
                            //         'big'
                            //     ]
                            // },
                            {
                                name: 'Bubbles sizes',
                                type: 'dropdown',
                                model: 'bubbles_sizes',
                                is: (spotlight) => spotlight.type === 'floating',

                                options: [
                                    'Small',
                                    'Medium',
                                    'Big'
                                ]
                            },
                            {
                                name: 'Primary color',
                                type: 'color',
                                model: 'color'
                            },
                            {
                                name: 'Font',
                                type: 'dropdown',
                                model: 'font',
                                options: [
                                    'DM sans',
                                    'Times New Roman', // Serif
                                    'Georgia',         // Serif
                                    'Garamond',        // Serif
                                    'Palatino',        // Serif
                                    'Bookman',         // Serif
                                    'Arial',           // Sans-Serif
                                    'Helvetica',       // Sans-Serif
                                    'Verdana',         // Sans-Serif
                                    'Tahoma',          // Sans-Serif
                                    'Trebuchet MS',    // Sans-Serif
                                    'Geneva',          // Sans-Serif
                                    'Courier New',     // Monospace
                                    'Lucida Console',  // Monospace
                                    'Monaco',          // Monospace
                                    'Comic Sans MS',   // Cursive
                                    'Brush Script MT', // Cursive
                                    'Impact',          // Fantasy
                                    'Charcoal'         // Fantasy
                                ]
                            },
                            {
                                name: 'Show rating',
                                type: 'toggle',
                                model: 'show_rating'
                            },
                            {
                                name: 'Show contact photo',
                                type: 'toggle',
                                model: 'show_contact_photo'
                            },
                            {
                                name: 'Show company logo',
                                type: 'toggle',
                                model: 'show_contact_company_logo'
                            },
                            {
                                name: 'Show call to action',
                                type: 'toggle',
                                model: 'show_call_to_action'
                            },
                            {
                                name: 'Call to action link',
                                type: 'text',
                                model: 'call_to_action_link'
                            },
                            {
                                name: 'Call to action text',
                                type: 'text',
                                model: 'call_to_action_text'
                            },
                            {
                                name: 'Show Skooad brand',
                                type: 'toggle',
                                model: 'show_skooad_brand',
                                pro: true
                            }

                        ]
                    },
                    {
                        tab: 'Options',
                        fields: [

                            {
                                name: 'Carousel show arrows',
                                type: 'toggle',
                                model: 'carousel_show_arrows',
                                is: (spotlight) => spotlight.type === 'carousel'
                            },
                            {
                                name: 'Carousel autoplay',
                                type: 'toggle',
                                model: 'carousel_autoplay',
                                is: (spotlight) => spotlight.type === 'carousel'
                            },
                            {
                                name: 'Carousel show bullets',
                                type: 'toggle',
                                model: 'carousel_show_pages',
                                is: (spotlight) => spotlight.type === 'carousel'
                            },
                            {
                                name: 'Floating enable on mobile',
                                type: 'toggle',
                                model: 'floating_enable_on_mobile',
                                is: (spotlight) => spotlight.type === 'floating'
                            },
                            {
                                name: 'Floating position',
                                type: 'dropdown',
                                model: 'floating_position',
                                options: ['right', 'left', 'top', 'bottom'],
                                is: (spotlight) => spotlight.type === 'floating'
                            },
                            {
                                name: 'Z-index',
                                type: 'int',
                                model: 'z_index',
                                is: (spotlight) => spotlight.type === 'floating'
                            },
                        ]
                    }
                ]
            },
            {
                title: 'Stories',
                icon: 'beating-heart',
                url: 'stories',
                form: [
                    {
                        fields: [
                            {
                                name: 'Add some love to your spotlight',
                                type: 'stories'
                            }
                        ]
                    }
                ]
            },
                // {
                //     title: 'Target',
                //     icon: 'bullseye',
                //     url: 'target',
                //     form: [
                //         {
                //             fields: [
                //                 // {
                //                 //     name: 'Type',
                //                 //     type: 'dropdown',
                //                 //     model: 'type',
                //                 //     options: ['floating', 'carousel']
                //                 // },
                //                 {
                //                     name: 'Domains',
                //                     type: 'list-string-array',
                //                     model: 'domains',
                //                     keys: []
                //                 }
                //             ]
                //         }
                //     ]
                // },
            ],
            testimonials: []
        };

    },

    //watch for changes in spotlight type and add or remove field Carousel target
    // watch: {
    //     'spotlight.type': function (newVal, oldVal) {
    //         if (newVal === 'carousel') {
    //             this.pages.find(p => p.url === 'target').form[0].fields.push({
    //                 name: 'Carousel target (ID of the div before)',
    //                 type: 'text',
    //                 model: 'carousel_target'
    //             })
    //         } else {
    //             this.pages.find(p => p.url === 'target').form[0].fields = this.pages.find(p => p.url === 'target').form[0].fields.filter(f => f.model !== 'carousel_target')
    //         }
    //     }
    // },

    async created() {
        if (this.$route.query.page) {
            this.pages.forEach(p => {
                if (p.url === this.$route.query.page) {
                    p.is = true;
                }
            })
            this.changePage(this.pages.find(p => p.url === this.$route.query.page))
        }
        await this.loadspotlight(this.$route.params.id);

        if (!this.spotlight.type) {
            this.dialogType = true
        }
        this.loading = false;
    },

    methods: {
        reload() {
            this.loading = true
            this.$nextTick(() => {
                this.loading = false
            })
        },
        inputChange() {
            this.loadTestimonial = true
            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        changePage(page) {
            this.loadTestimonial = true

            this.$router.push({ query: { page: page.url } });
            this.pages.forEach(p => {
                p.is = false;
            })
            page.is = true;

            this.$nextTick(() => {
                this.loadTestimonial = false
            })
        },
        returnBack() {
            if (this.activePage)
                this.pages.find(page => page.is).is = false
            else {
                this.$emit('reloadView')
                this.$router.push({ name: 'dashboard', params: { view: 'spotlights' } })
            }
        },
        async loadspotlight(id) {
            // this.loading = true
            const response = await supabase.from('spotlights').select(`*`).eq('id', id).limit(1)
            this.spotlight = response.data[0];
            // this.loading = false
            this.loadTestisOfSpot()

            return
        },

        async loadTestisOfSpot() {
            this.loading = true
            const response = await supabase.from('testisofspot').select(`*`).eq('spotlight', this.$route.params.id)
            this.testimonials = response.data;
            this.loading = false
            return
        },
        actionHeader() {
            this.$router.push({ name: 'dashboard', params: { view: 'spotlights' } });
        },
        async save(field, value) {
            const response = await supabase.from('spotlights').update({ [field]: value }).eq('id', this.$route.params.id)
        },
        async deleteInTable(table, id) {
            const response = await supabase.from(table).delete().eq('id', id)
        }
    }
}
</script>

<style lang="scss">
.dialog-type {
    max-width: 800px;

    .bloc {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fcfcfd;
        text-align: center;
        cursor: pointer;

        &:hover {
            transition: 0.3s;
            transform: scale(1.01);
        }



        img {
            width: 300px;
        }
    }
}

.mobile {
    .left {
        width: 100% !important;
    }

    .right {
        width: 100% !important;
    }
}
</style>

<style scoped lang="scss">
$left-size: 30%;

.spotlight {
    .left {
        padding: 20px;
        width: $left-size;
        background-color: #ffffff;
        min-width: 620px;

        .page {
            height: 100%;
            max-height: unset;

            :deep(.stories) {
                height: calc(100vh + 115px);
                overflow-y: auto;
                transform: scale(0.6) translateX(-35%) translateY(-32%);
                width: 170%;
            }

            .Stories {
                height: calc(100vh - 130px);
                overflow: hidden;
            }

            .Design {
                padding-right: 5px;
                height: calc(100vh - 185px);
                overflow-y: scroll;
            }
        }
    }

    .right {
        background-color: #fcfcfc;
        border-radius: 10px;
        overflow: hidden;
        width: calc(100% - #{$left-size});

        iframe {
            width: 100%;
            height: 100%;
        }

        .preview {
            min-width: 300px;
            min-height: 300px;
            width: calc(50vw - 500px);
            height: calc(50vw - 500px);
            border: 1px solid #c2c2c2;
            border-radius: 10px;

        }
    }

    .pages {
        max-height: calc(100vh - 350px);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5px;

        .page {
            cursor: pointer;
            border-radius: 50px;
            border: 1px solid #c2c2c2;
            padding: 15px 20px;

            &:hover {
                transition: 0.3s;
                transform: scale(1.01);
                background-color: #f5f5f5;
            }

            &.border-dot {
                border: 1px dashed #c2c2c2;
            }
        }
    }

    .page {
        max-height: calc(100vh - 350px);
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 20px;
        padding: 10px;
    }
}
</style>