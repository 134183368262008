<template>
    <div class="home">
        <p class="st">Welcome, {{ $r.user?.user_metadata.full_name.split(' ')[0]}}!</p>

        <QuickStart @updateView="$emit('updateView', $event)" />

        <AtAGlance />
    </div>
</template>

<script>
import { supabase } from '@/supabase'
import ProgressDoughnut from '@/components/utils/ProgressDoughnut.vue'
import QuickStart from '@/components/home/QuickStart.vue'
import AtAGlance from '@/components/home/AtAGlance.vue'

export default {
    name: "Home",
    data() {
        return {
        }
    },
    components: {
        QuickStart,
        AtAGlance
    }
};
</script>

<style lang="scss">
html,
body {
    overflow: hidden !important;
}
</style> 